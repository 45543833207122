import * as sync from '../../src/syncWorker'

import { AVAILABLE_ENTITIES, Repository, utils } from '../database'
import DateField, {
  datefieldDefaultOnChange,
} from '../components/material/DateField'
import React, { Component } from 'react'
import ResponsiveTable, { CELL_TYPES } from './../components/ResponsiveTable'
import { endLoading, startLoading } from './../redux/screen/actions'
import {
  isDateNullOrValid,
  missingRequiredFieldChecker,
  requiredFieldsEvaluator,
} from './../utils/formHelper'

import Button from '../components/material/Button'
import { Container } from '@material-ui/core'
import { LocalStorageHelper } from '../utils/localStorageHelper'
import MainContainer from '../components/MainContainer'
import Prompt from '../components/Prompt'
import { Q } from '@nozbe/watermelondb'
import TitleBar from '../components/TitleBar'
import TopBar from '../components/TopBar'
import TrainingsButton from './Trainings/TrainingsButton'
import _ from 'lodash'
import { connect } from 'react-redux'
import { getLoggedUser } from '../redux/auth/actions'
import hasInternet from './../utils/recognizeInternetConnection'
import { sortList } from '../utils/helper'
import track from 'react-tracking'
import { verify } from '../utils/verifyDuplicatePages'
import { withRouter } from 'react-router-dom'

@track(() => ({ page: 'Estação de Monta', date: new Date() }))
class RidingStation extends Component {
  constructor(props) {
    super(props)

    const sortedFarms = sortList(props.farms, 'nome')
    const estacaoDeMontaAnteriores = props.estacaoMonta.filter(
      (em) => em.descricao !== 'current'
    )

    const selectedEstacaoDeMonta = estacaoDeMontaAnteriores.reverse(
      (x) => x.valor
    )[0]?.id
    const beforeEstacaoDeMonta = estacaoDeMontaAnteriores
    const currentEstacaoMonta = props.estacaoMonta.find(
      (eM) => eM.descricao === 'current'
    )

    this.state = {
      allFarms: sortedFarms.filter((sF) => sF.estacaoMonta !== currentEstacaoMonta.id),
      selectedFarms: selectedEstacaoDeMonta
        ? sortedFarms.filter((sf) => sf.estacaoMonta !== currentEstacaoMonta.id)
        : sortedFarms,
      corrals: [],
      batches: [],
      cowCount: [],
      selectedToSyncFarms: [],
      forceSyncConfirmation: false,
      noConnection: false,
      noAlertSyncPull: localStorage.getItem('noAlertSyncPull') === 'true',
      alertSyncPull24h: this.getStatusAlertSyncPull24h(),
      selectedEstacaoDeMonta,
      total: 0,
      cooldownMessage: null,
      downloadFarm: true,
      syncFarms: false,
      currentEstacaoMontaId: currentEstacaoMonta.id,
      currentEstacaoMonta,
      copyFarm: [],
      copyFarmId: [],
      veterinarios: [this.props.user],
      inicioEstacaoMonta: null,
      fimEstacaoMonta: null,
      estacaoDeMontaAnterior: [beforeEstacaoDeMonta[0]],
    }

    this.handleSearch = this.handleSearch.bind(this)
    this.handleFilter = this.handleFilter.bind(this)
    this.createDefaultCorral = this.createDefaultCorral.bind(this)
    this.datefieldDefaultOnChange = datefieldDefaultOnChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.missingRequiredFieldChecker = missingRequiredFieldChecker.bind(this)
    this.requiredFieldsEvaluator = requiredFieldsEvaluator.bind(this)
    this.isDateNullOrValid = isDateNullOrValid.bind(this)
    this.getCowsByFarmId()
  }

  async createDefaultCorral(retiro, farmId) {
    if (!retiro) {
      const repository = new Repository(AVAILABLE_ENTITIES.CORRALS)
      await repository.post({
        nome: 'DEFAULT',
        fazendaId: farmId,
      })
    }
  }

  getStatusAlertSyncPull24h() {
    const lastSyncPull = localStorage.getItem('lastSyncPull')
    const diference = new Date() - new Date(lastSyncPull)
    if (diference >= 86400000) {
      //24h
      return true
    }
    return false
  }

  async getCowsByFarmId() {
    let corrals = await utils.getWithParam(
      AVAILABLE_ENTITIES.CORRALS,
      'farm_id',
      Q.oneOf(this.props.farms.map((b) => b.id))
    )

    this.setState({ corrals })

    let batches = await utils.getWithParam(
      AVAILABLE_ENTITIES.BATCHES,
      'corral_id',
      Q.oneOf(this.state.corrals.map((b) => b.id))
    )

    this.setState({ batches })

    let vacas = await utils.getWithParam(
      AVAILABLE_ENTITIES.D0S,
      'batch_id',
      Q.oneOf(this.state.batches.map((b) => b.id))
    )

    this.setState({ vacas })

    this.state.batches.map(
      (b) => (b.vacas = this.state.vacas.filter((v) => v.loteId === b.id))
    )
    this.state.corrals.map(
      (c) =>
      (c.batches = this.state.batches.filter(
        (b) =>
          b.retiroId === c.id &&
          b.originalBatchId == null &&
          b.deleted === false
      ))
    )
    this.state.selectedFarms.map(
      (f) =>
        (f.corrals = this.state.corrals.filter((c) => c.fazendaId === f.id))
    )

    this.setState({
      cowCount: this.state.selectedFarms.map((f) => this.getCount(f)),
    })

    this.getTotalCowsNumber(this.state.selectedEstacaoDeMonta)
  }

  getCount(farm) {
    const batches = farm.corrals.map((c) => c.batches)
    let count = {
      farmId: farm.id,
      countNumber: 0,
      estacaoMonta: farm.estacaoMonta,
    }
    batches.forEach((b) => {
      b.forEach((element) => {
        count.countNumber += element.vacas.length
      })
    })

    return count
  }

  handleSearch(e) {
    const val = e.target.value
    if (_.isEmpty(val)) {
      this.setState({ selectedFarms: this.state.allFarms })
    } else {
      const selectedFarms = this.state.allFarms.filter(
        (x) => x.nome.toLowerCase().indexOf(val.toLowerCase()) >= 0
      )
      this.setState({ selectedFarms })
    }
  }

  handleFilter(value) {
    if (!value) {
      this.setState({ selectedEstacaoDeMonta: null })
      this.setState({ selectedFarms: this.state.allFarms })
      this.getCowsByFarmId()
      return
    }
    if (!(value.descricao === 'current')) {
      this.setState({ selectedEstacaoDeMonta: value.id })
      this.getCowsByFarmId()
      this.filterDataByEM(value.id)
    }
  }

  filterDataByEM(value) {
    let selectedFarms = this.state.allFarms.filter(
      (sf) => sf.estacaoMonta === value
    )
    this.setState({ selectedFarms })
  }

  getTotalCowsNumber(estacaoMonta) {
    let totalCountNumber = 0

    if (this.state.cowCount.length > 0) {
      if (estacaoMonta) {
        for (let cow of this.state.cowCount) {
          if (cow.estacaoMonta === estacaoMonta) {
            totalCountNumber += cow.countNumber
          }
        }
      } else {
        for (let cow of this.state.cowCount) {
          totalCountNumber += cow.countNumber
        }
      }
    }

    this.setState({ total: totalCountNumber.toLocaleString('pt-BR') })
  }

  verifyFarmsIds(farmId) {
    if (localStorage.getItem('downloadFarms')) {
      if (localStorage.getItem('downloadFarms').indexOf(farmId) >= 0) {
        return true
      } else {
        return false
      }
    }
    return false
  }

  getTableColumnsData() {
    return [
      {
        name: 'Fazenda',
        type: CELL_TYPES.CHECKBOX,
        checked: (row) => this.state.copyFarmId.indexOf(row.id) >= 0,
        label: (row) => row.nome,
        onClick: (row) => {
          return () => {
            let copyFarmId = this.state.copyFarmId
            let copyFarm = this.state.copyFarm
            if (copyFarmId.indexOf(row.id) >= 0) {
              let pos = copyFarmId.indexOf(row.id)
              copyFarm.splice(pos, 1)
              copyFarmId.splice(copyFarmId.indexOf(row.id), 1)
            } else {
              copyFarmId.push(row.id)
              copyFarm.push(row)
            }

            this.setState({
              copyFarm,
              copyFarmId,
            })
          }
        },
        onClickLink: (row) => {
          return () =>
            (row.corrals && row.corrals.length > 0) ||
              this.verifyFarmsIds(row.id)
              ? this.props.history.push(`/farms/${row.id}`)
              : this.setState({ downloadFarm: false })
        },
        disabled: (row) =>
          this.state.copyFarmId.length >= 10 &&
          !(this.state.copyFarmId.indexOf(row.id) >= 0),
      },
      {
        name: 'Município/UF',
        type: CELL_TYPES.TEXT,
        label: (row) =>
          `${row.municipio}/${row.estado ? row.estado : row.pais}`,
      },
      {
        name: 'Estação de Monta',
        type: CELL_TYPES.TEXT,
        label: (row) =>
          row.estacaoMonta
            ? this.props.estacaoMonta.filter(
              (eM) => eM.id === row.estacaoMonta
            )[0].valor
            : '',
      },
      {
        name: 'Período da EM',
        type: CELL_TYPES.TEXT,
        label: (row) =>
          row.inicioEstacaoMonta && row.fimEstacaoMonta
            ? `${row.inicioEstacaoMonta.format(
              'DD/MM/YY'
            )} - ${row.fimEstacaoMonta.format('DD/MM/YY')}`
            : '',
      },
      {
        name: 'Retiro',
        type: CELL_TYPES.TEXT,
        label: (row) => `${row.retiro ? 'Sim' : 'Não'}`,
      },
      {
        name: 'Matrizes',
        type: CELL_TYPES.TEXT,
        label: (row) =>
          (row.corrals && row.corrals.length > 0) || this.verifyFarmsIds(row.id)
            ? this.state.cowCount.length > 0
              ? this.state.cowCount
                .filter((cc) => cc.farmId === row.id)[0]
                ?.countNumber.toLocaleString('pt-BR')
              : 0
            : '-',
      },
    ]
  }

  async initSync() {
    const farmsIds = []
    this.state.selectedFarms.map(
      (farm) =>
        ((farm.corrals && farm.corrals.length > 0) ||
          this.verifyFarmsIds(farm.id)) &&
        farmsIds.push(farm.id)
    )

    if (hasInternet()) {
      this.props.startLoading()
      if (farmsIds.length > 0) {
        await sync.pullFarms(farmsIds).then(() => {
          this.getCowsByFarmId()
          this.props.endLoading()
        })
      } else {
        await sync.forceSync(false, false).then(() => this.props.endLoading())
      }
    } else {
      this.setState({ noConnection: true })
    }
    this.setState({ syncFarms: true })
  }

  async SyncFarm(farms) {
    if (hasInternet()) {
      this.props.startLoading()
      await sync.pullFarms(farms).then(() => {
        this.getCowsByFarmId()
        this.props.endLoading()
      })
    } else {
      this.setState({ noConnection: true })
    }
    this.setState({ selectedToSyncFarms: [] })
  }
  @track({ action: 'Clicou no botao de voltar para Home' })
  handleBackToHome = () => this.props.history.push('/')

  getButtons() {
    return [
      {
        onClick: this.handleBackToHome,
        label: 'Voltar',
      },
    ]
  }

  async createFarm(payload) {
    const repository = new Repository(AVAILABLE_ENTITIES.FARMS)
    const farmUserRepository = new Repository(AVAILABLE_ENTITIES.FARM_USERS)
    const createReq = await repository.post(payload)

    if (createReq.success) {
      const farm = createReq.response
      let success = true
      await Promise.all(
        payload.veterinarios.map(async (user) => {
          const req = await farmUserRepository.post({
            idFazenda: farm.id,
            idUsuario: user.id,
          })
          if (!req.success) {
            console.log(
              `Error while creating relation of farm ${farm.id} with user ${user.id}: ${req.exception}`
            )
            success = false
          }
        }),
        await this.createDefaultCorral(payload.retiro, farm.id)
      )
      if (success) {
        console.log('Farm created successfully')
        this.props.history.push('/')
      }
    } else {
      console.log(
        `There was an error trying to create farm: ${createReq.exception}`
      )
    }
  }

  @track((props, state) => ({ action: 'Clicou no botao de Iniciar Estação de Monta', value: state.copyFarmId }))
  onSubmit() {
    const farmValues = this.state.copyFarm
    const farmNames = farmValues.map((farm) => farm.nome)

    LocalStorageHelper.add('duplicateFarmsNames', farmNames)

    farmValues.map((farm) => {
      let payload = {
        estado: farm.estado,
        municipio: farm.municipio,
        nome: farm.nome,
        inicioEstacaoMonta: this.state.inicioEstacaoMonta,
        fimEstacaoMonta: this.state.fimEstacaoMonta,
        nomeProprietario: farm.nomeProprietario,
        inscricaoEstadual: farm.inscricaoEstadual,
        pais: farm.pais,
        sistemaProducao: farm.sistemaProducao,
        estacaoMonta: farm.estacaoMonta,
      }
      payload.estacaoMonta = this.state.currentEstacaoMontaId
      payload.tamanho = parseInt(farm.tamanho)
      payload.usuarioId = this.props.user.id
      payload.retiro = farm.retiro ? farm.retiro : false
      payload.veterinarios = this.state.veterinarios

      let requiredFieldsState = this.requiredFieldsEvaluator()

      this.setState({
        copyFarm: [],
        copyFarmId: [],
      })
      if (requiredFieldsState !== null) {
        return this.setState(requiredFieldsState)
      } else {
        return this.createFarm(payload)
      }
    })
  }

  render() {
    return (
      <Container>
        <TopBar title={''} />
        <TitleBar
          title={`Replicar fazendas para Estação de Monta atual ${this.state.currentEstacaoMonta.valor}`}
          buttons={this.getButtons()}
        />
        <TrainingsButton page="/farms/estacaodemonta" />
        <MainContainer
          containerTitle='Fazendas'
          hasSearch
          filterProps={this.state.estacaoDeMontaAnterior}
          defaultFilterValue={this.state.selectedEstacaoDeMonta}
          filterLabel='Estação de Monta'
          searchLabel='Buscar'
          handleSearch={this.handleSearch}
          handleFilter={this.handleFilter}
        >
          <div className='grid app-farm-form'>
            <div className='grid-item p-12 t-6'>
              <DateField
                id='inicioEstacaoMonta'
                label='Início da Estação de Monta'
                value={this.state.inicioEstacaoMonta || null}
                onChange={this.datefieldDefaultOnChange('inicioEstacaoMonta')}
                onKeyDown={this.onEnterPress}
                error={
                  !_.isEmpty(this.state.inicioEstacaoMonta_error) ||
                  !this.isDateNullOrValid(this.state.inicioEstacaoMonta)
                }
                helperText={this.state.inicioEstacaoMonta_error}
              />
            </div>

            <div className='grid-item p-12 t-6'>
              <DateField
                id='fimEstacaoMonta'
                label='Fim da Estação de Monta'
                value={this.state.fimEstacaoMonta || null}
                onChange={this.datefieldDefaultOnChange('fimEstacaoMonta')}
                onKeyDown={this.onEnterPress}
                error={
                  !_.isEmpty(this.state.fimEstacaoMonta_error) ||
                  !this.isDateNullOrValid(this.state.fimEstacaoMonta)
                }
                helperText={this.state.fimEstacaoMonta_error}
              />
            </div>
          </div>
          <ResponsiveTable
            columns={this.getTableColumnsData()}
            rowsPerPage={10}
            data={this.state.selectedFarms}
            className='table-home'
          />
        </MainContainer>

        <Prompt
          visible={this.state.forceSyncConfirmation}
          title='Aviso!'
          message={`Este botão deve ser utilizado apenas nos casos em que os dados já sincronizados por outro usuário não foram atualizados em sua conta. Ao confirmar esta ação, você só poderá forçar uma nova sincronização após o período de 1 hora. Deseja prosseguir?`}
          buttons={[
            {
              label: 'Cancelar',
              onClick: () => this.setState({ forceSyncConfirmation: false }),
            },
            {
              label: 'Confirmar',
              onClick: () => {
                this.setState({ forceSyncConfirmation: false })
                sync.refreshApplication().then((res) => {
                  if (res != null) {
                    this.setState({ cooldownMessage: res })
                  }
                })
              },
            },
          ]}
        />

        <Prompt
          visible={
            !localStorage.getItem('downloadFarms') && this.state.syncFarms
          }
          title='Baixe os dados das fazendas!'
          message={`Antes de realizar a sincronização baixe os dados das fazendas.`}
          buttons={[
            {
              label: 'Ok',
              onClick: () => this.setState({ syncFarms: false }),
            },
          ]}
        />

        <Prompt
          visible={verify()}
          title='Página duplicada!'
          message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
            Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
          buttons={[
            {
              autoFocus: false,
            },
          ]}
        />

        <Prompt
          visible={this.state.noConnection}
          title='Não é possível realizar esta ação!'
          message={`Você está sem internet no momento. Verifique sua conexão e tente novamente mais tarde.`}
          buttons={[
            {
              label: 'Ok',
              onClick: () => this.setState({ noConnection: false }),
            },
          ]}
        />

        <Prompt
          visible={!this.state.downloadFarm}
          title='Atenção!'
          message={`É necessário realizar o download dos dados dessa fazenda para acessá-la.`}
          buttons={[
            {
              label: 'Ok',
              onClick: () => this.setState({ downloadFarm: true }),
            },
          ]}
        />

        <Prompt
          visible={this.state.cooldownMessage != null}
          title='Ação ainda não disponível!'
          message={this.state.cooldownMessage}
          buttons={[
            {
              label: 'Ok',
              onClick: () => this.setState({ cooldownMessage: null }),
            },
          ]}
        />

        {
          <div
            style={{
              position: 'fixed',
              bottom: '10px',
              left: '50%',
              marginLeft: '-185px',
              width: '370px',
              padding: '10px',
              background: 'white',
              border: '1.75px solid #EEE',
              borderRadius: '5px',
              zIndex: 1,
            }}
          >
            <Button
              label='Iniciar estação de monta'
              onClick={() => this.onSubmit()}
              style={{ width: 345 }}
              disabled={
                this.state.copyFarmId.length <= 0 ||
                !this.state.inicioEstacaoMonta ||
                !this.state.fimEstacaoMonta ||
                !this.isDateNullOrValid(this.state.fimEstacaoMonta) ||
                !this.isDateNullOrValid(this.state.inicioEstacaoMonta) ||
                this.props.user.roles[0].name === 'Cliente (somente visualização)'
              }
            />
            <br />
            <span>{`Máximo de 10 fazendas por vez, ${10 - this.state.copyFarmId.length
              } ${this.state.copyFarmId.length >= 9
                ? 'fazenda restante'
                : 'fazendas restantes'
              }.`}</span>
          </div>
        }
      </Container>
    )
  }
}

async function getFarms(user, isAdministrador) {
  if (!user) {
    return []
  }

  const repository = new Repository(AVAILABLE_ENTITIES.FARMS)
  const farmsData = isAdministrador
    ? await repository.get()
    : await repository.getByParam('user_id', user.id)
  const relatedFarmsData = isAdministrador
    ? { success: true, response: [] }
    : await repository.getByRelation('farms__rel__users', 'user_id', user.id)

  if (farmsData.success && relatedFarmsData.success) {
    return _.uniqBy(
      farmsData.response.concat(relatedFarmsData.response),
      (farm) => farm.id
    )
  }
  return []
}

const propsFromDatabase = async (props) => {
  const loggedUser = await getLoggedUser()
  const user = await utils.getElement(
    AVAILABLE_ENTITIES.USERS,
    loggedUser.userId
  )
  const isAdministrator = _.find(
    loggedUser.roles,
    (el) => el === 'Administrador'
  )
  return {
    user,
    estacaoMonta: await utils.getDomainValuesBy('Estação de Monta'),
    farms: await getFarms(user, isAdministrator),
  }
}

const mapDispatchToProps = (dispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
})

export default connect(
  null,
  mapDispatchToProps
)(utils.withPropsFromDatabase(propsFromDatabase, withRouter(RidingStation)))
