import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, withRouter } from 'react-router-dom'
import _ from 'lodash'
import { Container, Box, TextField } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'

import { utils } from '../../database'
import { getLoggedUser } from '../../redux/auth/actions'
import TitleBar from '../../components/TitleBar'
import MainContainer from '../../components/MainContainer'
import TopBar from '../../components/TopBar'
import ResponsiveTable from '../../components/ResponsiveTable'
import Button, { COLORS } from '../../components/material/Button'
import * as D from './data'

const AdminAddTypeDomain = (props) => {
  const [domainType, setDomainType] = useState([])
  const [filteredDomainType, setFilteredDomainType] = useState([])
  const [actions, setActions] = useState({})
  const [formData, setFormData] = useState({
    tipoDominioId: null,
    valorDominioPaiId: null,
  })
  const history = useHistory()

  const handleSearchDomainType = (e) => {
    const val = e.target.value

    if (_.isEmpty(val)) {
      setFilteredDomainType(domainType)
    } else {
      const filteredDomainType = domainType.filter(
        (item) => item.nome?.toLowerCase().indexOf(val?.toLowerCase()) >= 0
      )

      setFilteredDomainType(filteredDomainType)
    }
  }

  const handleChange = (e) => {
    e.preventDefault()

    const { name, value } = e.target

    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleDelete = async (url, id) => {
    try {
      await D.api.delete(`${url}/${id}`, props.params)

      const { domainType } = await D.fetchData()

      setDomainType(domainType)
      setFilteredDomainType(domainType)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmitDomainType = async (e) => {
    e.preventDefault()

    const payload = {
      ...formData,
    }

    try {
      await D.api[!!formData.id ? 'put' : 'post'](
        '/TipoDominio',
        payload,
        props.params
      )

      const { domainType } = await D.fetchData()

      setDomainType(domainType)
      setFilteredDomainType(domainType)
      setFormData({ tipoDominioId: null, valorDominioPaiId: null })
      setActions({ domainTypeModalAfterSubmitOrEdit: true })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setActions({})
    setFormData({
      tipoDominioId: null,
      valorDominioPaiId: null,
    })
  }, [])

  useEffect(() => {
    ; (async () => {
      const { domainType } = await D.fetchData()

      setDomainType(domainType)
      setFilteredDomainType(domainType)
    })()
  }, [])

  return (
    <Container>
      <TopBar title='Tipo Dominio' />
      <TitleBar
        title='Tipo Dominio'
        buttons={[
          {
            onClick: () => history.push('/lancamentos-do-administrador'),
            label: 'Voltar',
          },
        ]}
        paths={[
          {
            route: '/lancamentos-do-administrador',
            label: 'Lançamentos do administrador',
          },
          {
            label: 'Tipo Dominio',
          },
        ]}
      />
      <MainContainer>
        <form onSubmit={handleSubmitDomainType} className='grid d0-grid-form'>
          <div className='grid-item p-12 d-6'>
            <TextField
              id='nome'
              label='Nome'
              name='nome'
              variant='outlined'
              required
              type='text'
              autoComplete='off'
              style={{ width: '100%', backgroundColor: 'white' }}
              onChange={handleChange}
              value={formData?.nome || ''}
            />
          </div>
          <div className='grid-item p-12 d-6'>
            <TextField
              id='descricao'
              label='Descrição'
              name='descricao'
              variant='outlined'
              type='text'
              autoComplete='off'
              style={{ width: '100%', backgroundColor: 'white' }}
              onChange={handleChange}
              value={formData?.descricao || ''}
            />
          </div>
          <div className='grid-item p-12 p-display-flex p-justify-space-between'>
            <Button
              color={COLORS.GREY}
              label={!!formData.id ? 'Cancelar' : 'Limpar'}
              type='button'
              startIcon={!!!formData.id && <DeleteIcon />}
              onClick={() => {
                setFormData({
                  tipoDominioId: null,
                  valorDominioPaiId: null,
                })
                setActions({})
              }}
            />

            <Button
              label='Salvar'
              type='submit'
              startIcon={<SaveIcon />}
              containerStyle={{ textAlign: 'right' }}
            />
          </div>
        </form>

        {!actions.domainTypeEdit && (
          <MainContainer
            hasSearch
            searchLabel='Buscar'
            handleSearch={handleSearchDomainType}
            customStyle={{ marginTop: 0, paddingTop: 0 }}
          >
            <Box display='flex'>
              <ResponsiveTable
                hasTopPagination={false}
                columns={D.getDomainTypeTableData(setActions, setFormData)}
                rowsPerPage={20}
                data={filteredDomainType}
              />
            </Box>
          </MainContainer>
        )}
      </MainContainer>

      {D.getModals(actions, setActions, formData, handleDelete)}
    </Container>
  )
}

const propsFromDatabase = async () => {
  const { token } = await getLoggedUser()
  const params = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return {
    params,
  }
}

export default connect()(
  utils.withPropsFromDatabase(propsFromDatabase, withRouter(AdminAddTypeDomain))
)
