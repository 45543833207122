/* eslint-disable react/no-direct-mutation-state */

import { AVAILABLE_ENTITIES, Repository, utils } from '../../database'
import Autocomplete, {
  autocompleteDefaultOnChangeSavingID,
  autocompleteDomainValueOptionLabelSavingID,
  autocompleteDomainValueOptionLabelSavingIDUsingDescription,
  autocompleteOptionLabelUsingCustomFieldSavingID,
} from '../../components/material/Autocomplete'
import Button, { COLORS } from '../../components/material/Button'
import {
  FAZENDA,
  LOTE,
  REQUIRED_FIELD_MESSAGES,
  RETIRO,
  formatName,
  isDateNullOrValid,
  missingRequiredFieldChecker,
  requiredFieldsEvaluator,
} from '../../utils/formHelper'
import React, { Component } from 'react'
import ResponsiveTable, { CELL_TYPES } from '../../components/ResponsiveTable'
import TextField, {
  textfieldDefaultOnChange,
} from '../../components/material/TextField'
import {
  childbirthPredictionBull,
  childbirthPredictionIATF,
} from '../../utils/childbirthPrediction'
import { idConditionDG, idConditionDGFinal } from '../../utils/idsHelper'

import {
  ALL_FIELDS_TO_EXPORT,
  ANIMAL_EDITED_AFTER_D0,
  POWER_BI_FIELDS_TO_EXPORT,
} from '../../utils/constants'
import { Alert } from '@material-ui/lab'
import { Container } from '@material-ui/core'
import DateField from '../../components/material/DateField'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { LocalStorageHelper } from '../../utils/localStorageHelper'
import MainContainer from '../../components/MainContainer'
import MicIcon from '@material-ui/icons/Mic'
import MobileCard from '../../components/MobileCard'
import Prompt from '../../components/Prompt'
import { Q } from '@nozbe/watermelondb'
import SaveIcon from '@material-ui/icons/Save'
import SpeechRecognition from '../../components/SpeechRecognition'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import _ from 'lodash'
import { eccSpeechRecognition } from '../../utils/eccSpeechRecognition'
import { getBatchFile } from './../../redux/reports/actions'
import { getBatchesByBatchIds } from '../../utils/batches'
import { getLoggedUser } from '../../redux/auth/actions'
import hasInternet from '../../utils/recognizeInternetConnection'
import moment from 'moment'
import { sortList } from '../../utils/helper'
import track from 'react-tracking'
import { verify } from './../../utils/verifyDuplicatePages'
import { withRouter } from 'react-router-dom'

@track(() => ({ page: 'Listagem de Matrizes', date: new Date() }))
class Batch extends Component {
  constructor(props) {
    super(props)
    const sortedCows = sortList(props.vacas, 'codVaca')

    this.state = {
      allCows: sortedCows,
      selectedCows: this.props.batch?.matrizBloqueada
        ? sortedCows?.filter(
          (x) =>
            x.id?.toLowerCase() === this.props.batch?.matrizBloqueada ||
            x.dzeroOriginal?.toLowerCase() ===
            this.props.batch?.matrizBloqueada
        )
        : sortedCows,
      deleteConfirmationOpened: false,
      selectedCow: null,
      dateIatf: moment.utc(
        this.props.batch.dataIatf._d.valueOf() +
        this.props.currentBatchData.dayOfIatfNumber * 24 * 3600 * 1000
      ),
      showingAlreadyReservedConfirmation: false,
      loading: false,
      editCow: false,
      editCowRessinc: false,
      cowEdited: null,
      dataPartoIndividual: null,
      dataPartosInicio: this.props.batch.dataPartosInicio,
      dataPartosFim: this.props.batch.dataPartosFim,
      isDeliveryForecast: false,
      focusDate: false,
      lastEditCowId: '',
      showFields: false,
      noConnection: false,
      allFieldsOptionsExport: ALL_FIELDS_TO_EXPORT,
      selectedFieldsOptionsExport:
        JSON.parse(LocalStorageHelper.get('ProGerarSavedFieldsToExport')) ?? [],
    }

    this.codVaca = React.createRef()

    this.autocompleteDefaultOnChangeSavingID =
      autocompleteDefaultOnChangeSavingID.bind(this)
    this.autocompleteDomainValueOptionLabelSavingID =
      autocompleteDomainValueOptionLabelSavingID.bind(this)
    this.autocompleteOptionLabelUsingCustomFieldSavingID =
      autocompleteOptionLabelUsingCustomFieldSavingID.bind(this)
    this.autocompleteDomainValueOptionLabelSavingIDUsingDescription =
      autocompleteDomainValueOptionLabelSavingIDUsingDescription.bind(this)

    this.handleSearch = this.handleSearch.bind(this)
    this.handleEditCow = this.handleEditCow.bind(this)
    this.handleEditCowRessinc = this.handleEditCowRessinc.bind(this)
    this.deleteCow = this.deleteCow.bind(this)
    this.reserveBatch = this.reserveBatch.bind(this)
    this.unreserveBatch = this.unreserveBatch.bind(this)
    this.renderHeader = this.renderHeader.bind(this)
    this.textfieldDefaultOnChange = textfieldDefaultOnChange.bind(this)
    this.requiredFieldsEvaluator = requiredFieldsEvaluator.bind(this)
    this.missingRequiredFieldChecker = missingRequiredFieldChecker.bind(this)
    this.isDateNullOrValid = isDateNullOrValid.bind(this)
  }

  componentDidMount() {
    if (!!!LocalStorageHelper.get('ProGerarSavedFieldsToExport')) {
      LocalStorageHelper.add(
        'ProGerarSavedFieldsToExport',
        JSON.stringify(ALL_FIELDS_TO_EXPORT)
      )
    }
  }

  handleSearch(e) {
    const val = e.target.value

    if (_.isEmpty(val)) {
      this.setState({ selectedCows: this.state.allCows })
    } else {
      const filtedCodVaca = this.state.allCows.filter(
        (x) => x.codVaca?.toLowerCase().indexOf(val.toLowerCase()) >= 0
      )
      const filtedCodLeitorBastao = this.state.allCows.filter(
        (x) => x.codLeitorBastao?.toLowerCase().indexOf(val.toLowerCase()) >= 0
      )
      const filtedAllData = filtedCodVaca.concat(filtedCodLeitorBastao)

      const selectedCows = [...new Set(filtedAllData)]

      this.setState({ selectedCows })
    }
  }

  @track({ action: 'Clicou no botao de relatório' })
  async verifyConnection() {
    var isConnected = true //await verifyConnectionUser()

    if (isConnected) {
      this.props.history.push(
        `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}/batches/${this.props.batch.id}/report`
      )
    } else {
      alert(
        'Falha ao conectar. Esta funcionalidade não pode ser acessada no modo offline!'
      )
    }
  }

  @track({ action: 'Clicou no botao de liberar reserva' })
  async unreserveBatch() {
    let payload = this.props.currentBatch
    const loggedUser = await getLoggedUser()

    if (
      payload.reservedBy != null &&
      payload.reservedBy === loggedUser.userId
    ) {
      const repository = new Repository(AVAILABLE_ENTITIES.BATCHES)
      payload.reservedBy = null
      const updateRequest = await repository.update(payload)
      if (updateRequest.success) {
        this.setState({ reserveActionSuccess: true })
      } else {
        console.warn(
          `Ooops... there was an error updating batch ${this.props.batch.id}: ${updateRequest.exception}`
        )
      }
    } else {
      this.setState({ noConnection: true })
    }
  }

  @track({ action: 'Clicou no botao de reservar' })
  async reserveBatch() {
    let payload = this.props.currentBatch
    const loggedUser = await getLoggedUser()

    if (payload.reservedBy == null) {
      const repository = new Repository(AVAILABLE_ENTITIES.BATCHES)
      payload.reservedBy = loggedUser.userId
      const updateRequest = await repository.update(payload)
      if (updateRequest.success) {
        this.setState({ reserveActionSuccess: true })
      } else {
        console.warn(
          `Ooops... there was an error updating batch ${this.props.batch.id}: ${updateRequest.exception}`
        )
      }
    } else {
      this.setState({ showingAlreadyReservedConfirmation: true })
    }
  }

  @track((props, state, cow) => ({
    action: 'Deletou uma Matriz',
    value: cow[0],
  }))
  async deleteCow(selectedCow) {
    if (selectedCow === null) return
      ; (selectedCow.id ||
        selectedCow.dzeroOriginal === this.props.batch?.matrizBloqueada) &&
        this.blockBatch()

    const allIdsToSameCow = !selectedCow.dzeroOriginal
      ? [selectedCow.id]
      : this.props.allVacas
        .filter(
          (cow) =>
            cow.codVaca === selectedCow.codVaca &&
            (cow.dzeroOriginal == selectedCow.dzeroOriginal ||
              cow.id == selectedCow.dzeroOriginal)
        )
        .map((cow) => cow.id)

    const repository = new Repository(AVAILABLE_ENTITIES.D0S)
    const dnRepository = new Repository(AVAILABLE_ENTITIES.DNS)
    const iatfsRepository = new Repository(AVAILABLE_ENTITIES.IATFS)

    allIdsToSameCow.forEach(async (id) => {
      const request = await repository.delete(id)

      const dns = await utils.getWithParam(AVAILABLE_ENTITIES.DNS, 'vacaId', id)

      for (let dn of dns) {
        let dnRequest = await dnRepository.delete(dn.id)
        if (!dnRequest.success) {
          console.log(dnRequest.exception)
        }
      }

      const iatfs = await utils.getWithParam(
        AVAILABLE_ENTITIES.IATFS,
        'vacaId',
        id
      )

      for (let iatf of iatfs) {
        let iatfRequest = await iatfsRepository.delete(iatf.id)
        if (!iatfRequest.success) {
          console.log(iatfRequest.exception)
        }
      }

      let relatedCows = await utils.getWithParam(
        AVAILABLE_ENTITIES.D0S,
        'originalDZero_id',
        id
      )

      for (let relatedCow of relatedCows) {
        let relatedDNS = await utils.getWithParam(
          AVAILABLE_ENTITIES.DNS,
          'vacaId',
          relatedCow.id
        )

        for (let relatedDN of relatedDNS) {
          let relatedDnRequest = await dnRepository.delete(relatedDN.id)
          if (!relatedDnRequest.success) {
            console.log(relatedDnRequest.exception)
          }
        }

        let relatedIATFS = await utils.getWithParam(
          AVAILABLE_ENTITIES.IATFS,
          'vacaId',
          relatedCow.id
        )

        for (let relatedIATF of relatedIATFS) {
          let relatedIATFRequest = await iatfsRepository.delete(relatedIATF.id)
          if (!relatedIATFRequest.success) {
            console.log(relatedIATFRequest.exception)
          }
        }

        let relatedCowRequest = await repository.delete(relatedCow.id)

        if (!relatedCowRequest.success) {
          console.log(relatedCowRequest.exception)
        }
      }

      const newState = {
        deleteConfirmationOpened: false,
        selectedCow: null,
        selectedCowCode: null,
      }

      if (request.success) {
        console.log(`Delete of cow ${selectedCow.id} successful`)
        newState.allCows = this.state.allCows.filter(
          (x) => x.id !== selectedCow.id
        )
        newState.selectedCows = this.state.allCows.filter(
          (x) => x.id !== selectedCow.id
        )
      } else {
        console.log(
          `Delete of cow ${selectedCow.id} failed: ${request.exception}`
        )
      }

      this.setState(newState)
    })
  }

  @track((props, state) => ({
    action: 'Editou uma matriz',
    value: state.lastEditCowId,
  }))
  async handleEditCow() {
    let errorInputRequired = false
    const cowSelected = this.state.cowEdited

    try {
      this.setState({ loading: true })
      let payload = {
        ...cowSelected,
        ..._.pick(this.state, [
          'codVaca',
          'codLeitorBastao',
          'observacaoD0',
          'ecc',
          'idade_Nov_Pri',
          'responsavelId',
          'dataPartoIndividual',
          'dispositivoIntravaginal',
          'numero_Uso_Disp',
          'racaMatriz',
          'ordemMatriz',
          'clD0',
        ]),
      }

      payload.peso_EM = parseInt(this.state.peso_EM) || null
      payload.loteId = this.props.batch.id
      payload.dataProcesso = moment.utc()
      payload.omit_from_dg_final = false
      payload.iatf = 0

      if (this.props.batch.dias !== '') {
        payload.observacaoDG = ANIMAL_EDITED_AFTER_D0
      }
      let newAllCows = this.state.allCows
      let allCowsIds = []

      newAllCows.map((x) => allCowsIds.push(x.id))
      let pos = allCowsIds.indexOf(cowSelected.id)

      newAllCows.splice(pos, 1)

      let requiredFieldsState = this.requiredFieldsEvaluator()
      let validDates = this.isDateNullOrValid(this.state.dataPartoIndividual)

      if (
        (requiredFieldsState !== null || !validDates) &&
        !payload.codLeitorBastao
      ) {
        this.setState(requiredFieldsState)
        errorInputRequired = true
      } else if (
        (requiredFieldsState !== null || !validDates) &&
        !payload.codVaca
      ) {
        errorInputRequired = false
        payload.codVaca = payload.codLeitorBastao

        this.setState({ requiredFields: false, codVaca_error: null })
        const repository = new Repository(AVAILABLE_ENTITIES.D0S)
        const createReq = await repository.update(payload)

        if (createReq.success) {
          console.log('D0 updated successfully')

          newAllCows.push(createReq.response)
          newAllCows.reverse()

          this.setState({
            editCow: false,
            allCows: newAllCows,
            focusDate: false,
            dataPartoIndividual: null,
            lastEditCowId: createReq.response.id,
          })
        } else {
          console.log(
            `There was an error trying to update D0: ${createReq.exception}`
          )
        }
      } else {
        this.setState({ requiredFields: false })
        const repository = new Repository(AVAILABLE_ENTITIES.D0S)
        const createReq = await repository.update(payload)

        if (createReq.success) {
          console.log('D0 updated successfully')

          newAllCows.push(createReq.response)
          newAllCows.reverse()

          this.setState({
            editCow: false,
            allCows: newAllCows,
            focusDate: false,
            dataPartoIndividual: null,
            lastEditCowId: createReq.response.id,
          })
        } else {
          console.log(
            `There was an error trying to update D0: ${createReq.exception}`
          )
        }
      }
    } catch (exception) {
      console.log(`There was an error trying to update D0: ${exception}`)
    } finally {
      this.setState({ loading: false, searchError: false })
      if (errorInputRequired) {
        this.codVaca.current.focus()
      }
    }
  }

  async handleEditCowRessinc() {
    let errorInputRequired = false
    const cowSelected = this.state.cowEdited

    try {
      this.setState({ loading: true })
      const dzeroOriginal = this.props.allVacas.filter(
        (cow) => cow.id === cowSelected.dzeroOriginal
      )[0]
      const allToSameCow =
        cowSelected.iatf > 0
          ? this.props.allVacas.filter(
            (cow) =>
              cow.dzeroOriginal === dzeroOriginal.id ||
              cow.id === dzeroOriginal.id
          )
          : this.props.allVacas.filter(
            (cow) =>
              cow.id === cowSelected.id ||
              cow.dzeroOriginal === cowSelected.id
          )

      allToSameCow.forEach(async (cow) => {
        let payload = {
          ...cow,
          ..._.pick(this.state, [
            'codVaca',
            'codLeitorBastao',
            'observacaoD0',
            'ecc',
            'idade_Nov_Pri',
            'responsavelId',
            'dataPartoIndividual',
            'dispositivoIntravaginal',
            'numero_Uso_Disp',
            'racaMatriz',
            'ordemMatriz',
            'clD0',
            'peso_EM',
          ]),
        }

        if (this.props.batch.dias !== '' && !cow.observacaoDG) {
          payload.observacaoDG = ANIMAL_EDITED_AFTER_D0
        }
        let newAllCows = this.state.allCows
        let allCowsIds = []

        newAllCows.map((x) => allCowsIds.push(x.id))
        let pos = allCowsIds.indexOf(cow.id)

        pos >= 0 && newAllCows.splice(pos, 1)

        let requiredFieldsState = this.requiredFieldsEvaluator()
        let validDates = this.isDateNullOrValid(this.state.dataPartoIndividual)

        if (
          (requiredFieldsState !== null || !validDates) &&
          !payload.codLeitorBastao
        ) {
          this.setState(requiredFieldsState)
          errorInputRequired = true
        } else if (
          (requiredFieldsState !== null || !validDates) &&
          !payload.codVaca
        ) {
          errorInputRequired = false
          payload.codVaca = payload.codLeitorBastao

          this.setState({ requiredFields: false, codVaca_error: null })
          const repository = new Repository(AVAILABLE_ENTITIES.D0S)
          const createReq = await repository.update(payload)
          if (createReq.success) {
            console.log('D0 updated successfully')

            newAllCows.push(createReq.response)
            newAllCows.reverse()

            this.setState({
              editCow: false,
              editCowRessinc: false,
              allCows: newAllCows,
              focusDate: false,
              dataPartoIndividual: null,
              lastEditCowId: createReq.response.id,
            })
          } else {
            console.log(
              `There was an error trying to update D0: ${createReq.exception}`
            )
          }
        } else if (cow.iatf > 0) {
          this.setState({ requiredFields: false })
          const repository = new Repository(AVAILABLE_ENTITIES.D0S)
          const createReq = await repository.update(payload)

          if (createReq.success) {
            console.log('D0 updated successfully')

            this.setState({
              editCow: false,
              editCowRessinc: false,
            })
          } else {
            console.log(
              `There was an error trying to update D0: ${createReq.exception}`
            )
          }
        } else {
          this.setState({ requiredFields: false })
          const repository = new Repository(AVAILABLE_ENTITIES.D0S)
          const createReq = await repository.update(payload)

          if (createReq.success) {
            console.log('D0 updated successfully')
            newAllCows.push(createReq.response)
            newAllCows.reverse()

            this.setState({
              editCow: false,
              allCows: newAllCows,
              focusDate: false,
              dataPartoIndividual: null,
              lastEditCowId: createReq.response.id,
            })
          } else {
            console.log(
              `There was an error trying to update D0: ${createReq.exception}`
            )
          }
        }
      })
    } catch (exception) {
      console.log(`There was an error trying to update D0: ${exception}`)
    } finally {
      this.setState({ loading: false, searchError: false })
      if (errorInputRequired) {
        this.codVaca.current.focus()
      }
    }
  }

  async blockBatch(d0Id = null) {
    const repository = new Repository(AVAILABLE_ENTITIES.BATCHES)
    const blockBatchRequest = await repository.update({
      id: this.props.batch.id,
      matrizBloqueada: d0Id,
    })
    if (blockBatchRequest.success) {
      LocalStorageHelper.remove('iatfCowsD0AfterResync')
      this.props.batch.matrizBloqueada = null
      console.log('Cow updated successfully')
    }
  }

  renderColumnLabel(row, rowItem, prop, label) {
    return (
      this.props[prop] &&
      row[rowItem] &&
      this.props[prop].find((x) => x.id === row[rowItem])[label]
    )
  }

  @track({ action: 'Clicou no botao de exportar' })
  handleExport = (selectedFieldsOptionsExport, isPowerBI = false) => {
    if (hasInternet()) {
      getBatchFile(
        this.props.batch.id,
        selectedFieldsOptionsExport,
        isPowerBI
          ? `${formatName(this.props.currentBatch.nomeLote, LOTE)}-powerbi`
          : formatName(this.props.currentBatch.nomeLote, LOTE)
      )
    } else {
      this.setState({ noConnection: true })
    }
  }

  @track({ action: 'Clicou no botao de Ressinc' })
  handleRessincBtn = () =>
    this.props.history.push(`/Resync/${this.props.currentBatch.id}`)

  @track((props) => ({
    action: 'Clicou no botao de manejo',
    value: props.history.location.pathname,
  }))
  handleManagementBtn = () => {
    this.props.history.push(
      `/${this.props.isLastDay || this.props.hasIatf
        ? 'IATF'
        : !this.props.currentBatch.dias || this.props.currentBatch.dias === ''
          ? this.props.currentBatch.isResync
            ? this.props.isPrecoce
              ? 'D0Resync'
              : 'DGRessinc'
            : 'D0'
          : this.props.nextDay
            ? this.props.currentBatch.isResync &&
              this.props.isPrecoce &&
              !this.props.currentBatch.isFinalize
              ? 'DGRessinc'
              : 'DN'
            : this.props.pastDG && this.props.currentBatch.isFinalize
              ? 'DGFinal'
              : 'DG'
      }/${this.props.currentBatch.id}`,
      {
        manejo: this.handleToAppearManejoBtn(),
        farm: {
          id: this.props.farm.id,
          label: formatName(this.props.farm.nome, FAZENDA),
        },
        retiro: {
          id: this.props.corral.id,
          label: formatName(this.props.corral.nome, RETIRO),
        },
      }
    )
  }

  @track({ action: 'Clicou no botao de editar' })
  handleEditBatch = () =>
    this.props.history.push(
      `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}/batches/${this.props.currentBatch.id}/update`
    )

  handleToAppearRessincBtn = () => {
    if (
      (!this.props.currentBatch.reservedBy ||
        (this.props.currentBatch.reservedBy &&
          this.props.currentBatch.reservedBy === this.props.user.userId)) &&
      !this.props.pastDGFinal &&
      this.props.currentBatch.dias &&
      !this.props.nextDay
    ) {
      return {
        label: 'Ressinc',
        onClick: this.handleRessincBtn,
        disabled: this.props.batch?.matrizBloqueada ? true : false,
      }
    }
  }

  handleToAppearManejoBtn = () => {
    if (this.props.isLastDay || this.props.hasIatf) {
      return 'IATF'
    } else if (
      !this.props.currentBatch.dias ||
      this.props.currentBatch.dias === ''
    ) {
      if (this.props.currentBatch.isResync && !this.props.isPrecoce) {
        return 'DG/D0'
      } else {
        return 'D0'
      }
    } else if (this.props.nextDay) {
      if (
        this.props.currentBatch.isResync &&
        this.props.isPrecoce &&
        !this.props.currentBatch.isFinalize
      ) {
        return `DG/${this.props.nextDay.valor}`
      } else {
        return this.props.nextDay.valor
      }
    } else if (this.props.pastDG && this.props.currentBatch.isFinalize) {
      return 'DG Final'
    } else {
      return 'DG'
    }
  }

  handleToApperarManagementBtn = () => {
    if (
      !this.props.currentBatch.reservedBy ||
      (this.props.currentBatch.reservedBy &&
        this.props.currentBatch.reservedBy === this.props.user.userId)
    ) {
      return {
        label: this.handleToAppearManejoBtn(),
        onClick: this.handleManagementBtn,
        disabled: this.props.batch?.matrizBloqueada ? true : false,
      }
    }
  }

  getTableColumnsData() {
    return [
      {
        name: 'Matriz (ID)',
        type: CELL_TYPES.TEXT,
        grid: 2,
        label: (row) => row.codVaca,
      },
      {
        name: 'Raça',
        type: CELL_TYPES.TEXT,
        label: (row) =>
          this.renderColumnLabel(row, 'racaMatriz', 'racaMatriz', 'descricao'),
      },
      {
        name: 'Ordem',
        type: CELL_TYPES.TEXT,
        label: (row) =>
          this.renderColumnLabel(
            row,
            'ordemMatriz',
            'ordemMatriz',
            'descricao'
          ),
      },
      {
        name: 'ECC',
        type: CELL_TYPES.TEXT,
        label: (row) => this.renderColumnLabel(row, 'ecc', 'ecc', 'valor'),
      },
      {
        name: 'Peso',
        type: CELL_TYPES.TEXT,
        label: (row) => row.peso_EM,
      },
      {
        name: 'Dispositivo',
        type: CELL_TYPES.TEXT,
        grid: 2,
        label: (row) =>
          this.renderColumnLabel(
            row,
            'dispositivoIntravaginal',
            'implantes',
            'valor'
          ),
      },
      {
        name: 'Uso',
        type: CELL_TYPES.TEXT,
        label: (row) =>
          this.renderColumnLabel(
            row,
            'numero_Uso_Disp',
            'usoImplantes',
            'valor'
          ),
      },
      {
        name: 'Idade',
        type: CELL_TYPES.TEXT,
        label: (row) =>
          this.renderColumnLabel(row, 'idade_Nov_Pri', 'idadeMatriz', 'valor'),
      },
      {
        name: 'CL',
        type: CELL_TYPES.TEXT,
        label: (row) => this.renderColumnLabel(row, 'clD0', 'clD0', 'valor'),
      },
      {
        name: this.state.selectedCows.find((item) => item.partidaName)
          ? 'Sêmen'
          : '',
        type: CELL_TYPES.TEXT,
        label: (row) => row.partidaName,
      },
      {
        name: 'Prenhe',
        type: CELL_TYPES.TEXT_CENTER,
        label: (row) => {
          if (
            row.condicaoDG === idConditionDG.pregnant &&
            (row.condicaoDGFinal === idConditionDGFinal.pregnantIATF ||
              row.condicaoDGFinal === idConditionDGFinal.pregnantBull ||
              row.condicaoDGFinal === null)
          ) {
            return 'Sim'
          } else if (
            row.condicaoDG !== idConditionDG.pregnant &&
            (row.condicaoDGFinal === idConditionDGFinal.pregnantIATF ||
              row.condicaoDGFinal === idConditionDGFinal.pregnantBull)
          ) {
            return 'Sim'
          } else {
            return 'Não'
          }
        },
      },
      {
        name: this.state.selectedCows.find(
          (item) =>
            (item.condicaoDG === idConditionDG.pregnant &&
              (item.condicaoDGFinal === idConditionDGFinal.pregnantIATF ||
                item.condicaoDGFinal === idConditionDGFinal.pregnantBull ||
                item.condicaoDGFinal === null)) ||
            (item.condicaoDG !== idConditionDG.pregnant &&
              (item.condicaoDGFinal === idConditionDGFinal.pregnantIATF ||
                item.condicaoDGFinal === idConditionDGFinal.pregnantBull))
        )
          ? 'Previsão de partos'
          : '',
        type: CELL_TYPES.TEXT_CENTER,
        label: (row) => {
          if (
            row.condicaoDG === idConditionDG.pregnant &&
            (row.condicaoDGFinal === idConditionDGFinal.pregnantIATF ||
              row.condicaoDGFinal === null)
          ) {
            this.state.isDeliveryForecast = true
            return childbirthPredictionIATF(this.state.dateIatf)
          } else if (
            row.condicaoDG !== idConditionDG.pregnant &&
            row.condicaoDGFinal === idConditionDGFinal.pregnantIATF
          ) {
            this.state.isDeliveryForecast = true
            return childbirthPredictionIATF(this.state.dateIatf)
          } else if (row.condicaoDGFinal === idConditionDGFinal.pregnantBull) {
            this.state.isDeliveryForecast = true
            return childbirthPredictionBull(
              row.dataDgFinal,
              row.diasGestacaoTouro
            )
          } else {
            return ''
          }
        },
      },
      {
        type: CELL_TYPES.BUTTON_ARR,
        buttons: (row) => {
          if (!this.props.nextDay || this.props.iatfCount > 1) {
            return _.compact([
              {
                icon: <EditIcon />,
                label: 'Editar',
                onClick: () => {
                  this.setState({
                    editCowRessinc: true,
                    editCow: true,
                    selectedCow: row?.id,
                    focusDate: row?.dataPartoIndividual ? true : false,
                    selectedCowCode: row?.codVaca,
                    cowEdited: row,
                    codVaca:
                      row.codVaca === row?.codLeitorBastao
                        ? null
                        : row?.codVaca,
                    codLeitorBastao: row?.codLeitorBastao,
                    ecc: row?.ecc,
                    peso_EM: row?.peso_EM,
                    clD0: row?.clD0,
                    dataPartoIndividual: row?.dataPartoIndividual,
                    observacaoD0: row?.observacaoD0,
                    idade_Nov_Pri: row?.idade_Nov_Pri,
                    racaMatriz: row?.racaMatriz,
                    ordemMatriz: row?.ordemMatriz,
                    dispositivoIntravaginal: row?.dispositivoIntravaginal,
                    numero_Uso_Disp: row?.numero_Uso_Disp,
                    responsavelId: row?.responsavelId,
                  })
                },
              },
              {
                icon: <DeleteIcon />,
                label: 'Excluir',
                onClick: () =>
                  this.setState({
                    deleteConfirmationOpened: true,
                    selectedCow: row,
                    selectedCowCode: row.codVaca,
                  }),
                disabled:
                  this.props.user.roles[0] === 'Cliente (somente visualização)',
              },
            ])
          } else {
            return _.compact([
              {
                icon: <EditIcon />,
                label: 'Editar',
                disabled: !this.props.nextDay ? true : false,
                onClick: () => {
                  this.setState({
                    editCow: true,
                    selectedCow: row?.id,
                    focusDate: row?.dataPartoIndividual ? true : false,
                    selectedCowCode: row?.codVaca,
                    cowEdited: row,
                    codVaca:
                      row.codVaca === row?.codLeitorBastao
                        ? null
                        : row?.codVaca,
                    codLeitorBastao: row?.codLeitorBastao,
                    ecc: row?.ecc,
                    peso_EM: row?.peso_EM,
                    clD0: row?.clD0,
                    dataPartoIndividual: row?.dataPartoIndividual,
                    observacaoD0: row?.observacaoD0,
                    idade_Nov_Pri: row?.idade_Nov_Pri,
                    racaMatriz: row?.racaMatriz,
                    ordemMatriz: row?.ordemMatriz,
                    dispositivoIntravaginal: row?.dispositivoIntravaginal,
                    numero_Uso_Disp: row?.numero_Uso_Disp,
                    responsavelId: row?.responsavelId,
                  })
                },
              },
              {
                icon: <DeleteIcon />,
                label: 'Excluir',
                onClick: () =>
                  this.setState({
                    deleteConfirmationOpened: true,
                    selectedCow: row,
                    selectedCowCode: row.codVaca,
                  }),
                disabled:
                  this.props.user.roles[0] === 'Cliente (somente visualização)',
              },
            ])
          }
        },
      },
    ]
  }

  getMobileData() {
    return {
      title: 'Matriz',
      values: this.state.selectedCows.map((item) => ({
        Matriz: item.codVaca,
        Raça: this.renderColumnLabel(
          item,
          'racaMatriz',
          'racaMatriz',
          'descricao'
        ),
        Ordem: this.renderColumnLabel(
          item,
          'ordemMatriz',
          'ordemMatriz',
          'descricao'
        ),
        ECC:
          this.renderColumnLabel(item, 'ecc', 'ecc', 'valor') === null
            ? '-'
            : this.renderColumnLabel(item, 'ecc', 'ecc', 'valor'),
        Peso: item.peso_EM === null ? '-' : item.peso_EM,
        Dispositivo: this.renderColumnLabel(
          item,
          'dispositivoIntravaginal',
          'implantes',
          'valor'
        ),
        Uso: this.renderColumnLabel(
          item,
          'numero_Uso_Disp',
          'usoImplantes',
          'valor'
        ),
        Idade:
          this.renderColumnLabel(
            item,
            'idade_Nov_Pri',
            'idadeMatriz',
            'valor'
          ) === null
            ? '-'
            : this.renderColumnLabel(
              item,
              'idade_Nov_Pri',
              'idadeMatriz',
              'valor'
            ),
        CL:
          this.renderColumnLabel(item, 'clD0', 'clD0', 'valor') === null
            ? '-'
            : this.renderColumnLabel(item, 'clD0', 'clD0', 'valor'),
        Prenhe:
          item.condicaoDG === idConditionDG.pregnant &&
            (item.condicaoDGFinal === idConditionDGFinal.pregnantIATF ||
              item.condicaoDGFinal === idConditionDGFinal.pregnantBull ||
              item.condicaoDGFinal === null)
            ? 'Sim'
            : item.condicaoDG !== idConditionDG.pregnant &&
              (item.condicaoDGFinal === idConditionDGFinal.pregnantIATF ||
                item.condicaoDGFinal === idConditionDGFinal.pregnantBull)
              ? 'Sim'
              : 'Não',
        'Previsão de partos':
          item.condicaoDG === idConditionDG.pregnant &&
            (item.condicaoDGFinal === idConditionDGFinal.pregnantIATF ||
              item.condicaoDGFinal === null)
            ? childbirthPredictionIATF(this.state.dateIatf)
            : item.condicaoDG !== idConditionDG.pregnant &&
              item.condicaoDGFinal === idConditionDGFinal.pregnantIATF
              ? childbirthPredictionIATF(this.state.dateIatf)
              : item.condicaoDGFinal === idConditionDGFinal.pregnantBull
                ? childbirthPredictionBull(item.dataDgFinal, item.diasGestacaoTouro)
                : '-',
        Actions:
          !this.props.nextDay || this.props.iatfCount > 1
            ? _.compact([
              {
                icon: <DeleteIcon />,
                label: 'Excluir',
                onClick: () =>
                  this.setState({
                    deleteConfirmationOpened: true,
                    selectedCow: item,
                    selectedCowCode: item.codVaca,
                  }),
                disabled:
                  this.props.user.roles[0] ===
                  'Cliente (somente visualização)',
              },
            ])
            : _.compact([
              {
                icon: <EditIcon />,
                label: 'Editar',
                disabled: !this.props.nextDay ? true : false,
                onClick: () => {
                  this.setState({
                    editCow: true,
                    selectedCow: item?.id,
                    focusDate: item?.dataPartoIndividual ? true : false,
                    selectedCowCode: item?.codVaca,
                    cowEdited: item,
                    codVaca:
                      item.codVaca === item?.codLeitorBastao
                        ? null
                        : item?.codVaca,
                    codLeitorBastao: item?.codLeitorBastao,
                    ecc: item?.ecc,
                    peso_EM: item?.peso_EM,
                    clD0: item?.clD0,
                    dataPartoIndividual: item?.dataPartoIndividual,
                    observacaoD0: item?.observacaoD0,
                    idade_Nov_Pri: item?.idade_Nov_Pri,
                    racaMatriz: item?.racaMatriz,
                    ordemMatriz: item?.ordemMatriz,
                    dispositivoIntravaginal: item?.dispositivoIntravaginal,
                    numero_Uso_Disp: item?.numero_Uso_Disp,
                    responsavelId: item?.responsavelId,
                  })
                },
              },
              {
                icon: <DeleteIcon />,
                label: 'Excluir',
                onClick: () =>
                  this.setState({
                    deleteConfirmationOpened: true,
                    selectedCow: item,
                    selectedCowCode: item.codVaca,
                  }),
                disabled:
                  this.props.user.roles[0] ===
                  'Cliente (somente visualização)',
              },
            ]),
      })),
      noDataMessage: 'Ainda não existe nenhuma vaca neste lote!',
    }
  }

  renderHeader() {
    return (
      <div
        className={`grid ${window.innerWidth > 1000 ? 'grid-dg-final' : 'grid-dg'
          }  p-margin-bottom-1`}
        style={{ padding: 0 }}
      >
        <div className='grid-item p-12 t-8 header-info-left'>
          <ul>
            <li className='p-font-weight-700'>
              Protocolo: {this.props.currentBatchData.protocolo}
            </li>
            <li className='p-font-weight-700'>
              Período e Hora do Manejo:{' '}
              {this.props.currentBatchData.periodoEHorarioManejo}
            </li>
            <li className='p-font-weight-700'>
              Progesterona Pós IATF:{' '}
              {this.props.currentBatchData.progesteronaPosIATF}
            </li>
            <li className='p-font-weight-700'>
              Vacina Reprodutiva:{' '}
              {this.props.currentBatchData.vacinaReprodutivaLote}
            </li>
          </ul>
          <div
            className='p-margin-vertical-1 p-margin-horizontal-2'
            style={{ width: '1px', borderLeft: '1px solid rgb(224 224 224)' }}
          />
          <ul>
            <li className='p-font-weight-700'>
              Dia do D0: D0 ({this.props.currentBatchData.d0Date})
            </li>
            {!!this.props.currentBatchData.dayOfWithdrawal && (
              <li className='p-font-weight-700'>
                Dia da Retirada do Dispositivo:{' '}
                {this.props.currentBatchData.dayOfWithdrawal} (
                {this.props.currentBatchData.withdrawalDate})
              </li>
            )}
            <li className='p-font-weight-700'>
              Dia da IATF: {this.props.currentBatchData.dayOfIatf} (
              {this.props.currentBatchData.iatfDate})
            </li>
          </ul>
        </div>

        <div className='grid-item p-12 t-4 header-info-right'>
          <h2 className='title-bigger'>
            TOTAL DE MATRIZES: {this.state.selectedCows.length}
          </h2>
        </div>
      </div>
    )
  }

  render() {
    const importDataButton = this.props.iatfCount <= 1 && {
      onClick: () =>
        this.props.history.push('/importar-dados', {
          batchId: this.props.batch.id,
          farmName: this.props.farm.nome,
          farmId: this.props.farm.id,
        }),
      label: 'Importar dados do lote',
    }

    return (
      <Container>
        <TopBar title={formatName(this.props.farm.nome, FAZENDA)} />
        <TitleBar
          title={
            formatName(this.props.currentBatch.nomeLote, LOTE) +
            ' - Listagem de Matrizes'
          }
          buttons={_.compact([
            this.handleToAppearRessincBtn(),
            this.handleToApperarManagementBtn(),
            importDataButton,
            !this.props.currentBatch.reservedBy ||
              (this.props.currentBatch.reservedBy &&
                this.props.currentBatch.reservedBy !== this.props.user.userId)
              ? {
                onClick: () =>
                  hasInternet()
                    ? this.reserveBatch()
                    : this.setState({ noConnection: true }),
                label: 'Reservar',
                disabled:
                  this.props.user.roles[0] ===
                  'Cliente (somente visualização)',
              }
              : {
                onClick: () =>
                  hasInternet()
                    ? this.unreserveBatch()
                    : this.setState({ noConnection: true }),
                label: 'Liberar Reserva',
                disabled:
                  this.props.user.roles[0] ===
                  'Cliente (somente visualização)',
              },
            {
              onClick: () => this.verifyConnection(),
              label: 'Relatório do Lote',
            },
            {
              hasDropdown: true,
              buttons: [
                {
                  onClick: () => this.handleExport([]),
                  label: 'Exportar Lote',
                },
                {
                  onClick: () => this.setState({ showFields: true }),
                  label: 'Exportação Personalizada',
                },
                {
                  onClick: () =>
                    this.handleExport(POWER_BI_FIELDS_TO_EXPORT, true),
                  label: 'Exportação para o POWER BI',
                },
              ],
              label: 'Exportação',
            },
            {
              onClick: this.handleEditBatch,
              label: 'Editar',
            },
          ])}
          paths={[
            {
              route: `/farms/${this.props.farm.id}`,
              label: formatName(this.props.farm.nome, FAZENDA),
            },
            ...this.props.paths,
          ]}
        />

        <MainContainer
          hasSearch={!this.state.editCow}
          searchLabel='Buscar'
          preSearchChildren={this.renderHeader()}
          handleSearch={this.handleSearch}
        >
          {this.state.editCow ? (
            <div className='grid d0-grid-form'>
              <div className='grid-item p-10 d-4'>
                <TextField
                  id='codVaca'
                  label='ID1/Matriz'
                  inputRef={this.codVaca}
                  autoFocus
                  disabled={this.state.loading}
                  onChange={(e) => {
                    this.textfieldDefaultOnChange(e)
                    this.setState({ enterSubmit: true })
                  }}
                  onKeyDown={this.onEnterPress}
                  value={this.state.codVaca || null}
                  error={!_.isEmpty(this.state.codVaca_error)}
                  helperText={this.state.codVaca_error}
                />
              </div>

              <div className='grid-item p-2 d-1'>
                <Button
                  secondary
                  small
                  tabIndex='-1'
                  style={{
                    height: '55px',
                    width: '100%',
                  }}
                  onClick={async () => {
                    try {
                      const resposta = await SpeechRecognition()
                      if (resposta !== null)
                        this.setState({
                          codVaca: resposta,
                          codVaca_error:
                            _.isEmpty(resposta) &&
                            REQUIRED_FIELD_MESSAGES.DEFAULT,
                        })
                    } catch (e) {
                      console.log(e)
                    }
                  }}
                  label={<MicIcon />}
                  containerStyle={{ textAlign: 'center' }}
                />
              </div>

              <div className='grid-item p-10 d-4'>
                <TextField
                  id='codLeitorBastao'
                  label='ID2/Leitor de bastão'
                  disabled={this.state.loading}
                  onChange={(e) => {
                    this.textfieldDefaultOnChange(e)
                    this.setState({ enterSubmit: true, codVaca_error: null })
                  }}
                  onKeyDown={this.onEnterPress}
                  value={this.state.codLeitorBastao || null}
                />
              </div>

              <div className='grid-item p-12 t-5 d-2'>
                <Autocomplete
                  id='ecc'
                  options={this.props.ecc}
                  value={this.state.ecc || null}
                  onChange={this.autocompleteDefaultOnChangeSavingID('ecc')}
                  onKeyDown={this.state.ecc && this.onEnterPress}
                  onFocus={() => this.setState({ enterSubmit: false })}
                  getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                    this.props.ecc
                  )}
                  useDefaultOptionSelected
                  label='ECC'
                  error={!_.isEmpty(this.state.ecc_error)}
                  helperText={this.state.ecc_error}
                />
              </div>

              <div className='grid-item p-2 d-1'>
                <Button
                  secondary
                  small
                  tabIndex='-1'
                  style={{
                    height: '55px',
                    width: '100%',
                  }}
                  onClick={async () => {
                    try {
                      const answerToUser = eccSpeechRecognition(
                        await SpeechRecognition()
                      )

                      if (answerToUser !== null) {
                        this.setState({
                          ecc: answerToUser,
                        })
                      }
                    } catch (e) {
                      console.log(e)
                    }
                  }}
                  label={<MicIcon />}
                  containerStyle={{ textAlign: 'center' }}
                />
              </div>

              <div className='grid-item p-10 t-5 d-2'>
                <TextField
                  id='peso_EM'
                  label='Peso Inicial'
                  type='number'
                  // onChange={this.textfieldDefaultOnChange}
                  onChange={(e) => {
                    this.textfieldDefaultOnChange(e)
                    this.setState({ enterSubmit: true })
                  }}
                  onKeyDown={this.onEnterPress}
                  value={this.state.peso_EM || null}
                  error={!_.isEmpty(this.state.peso_EM_error)}
                  helperText={this.state.peso_EM_error}
                />
              </div>

              <div className='grid-item p-2 t-2 d-1'>
                <Button
                  secondary
                  small
                  tabIndex='-1'
                  style={{
                    height: '55px',
                    width: '100%',
                  }}
                  onClick={async () => {
                    const resposta = await SpeechRecognition()
                    if (resposta !== null)
                      this.setState({
                        peso_EM: resposta,
                        peso_EM_error: null,
                      })
                  }}
                  label={<MicIcon />}
                  containerStyle={{ textAlign: 'center' }}
                />
              </div>

              <div className='grid-item p-12 t-4 d-2'>
                <Autocomplete
                  id='clD0'
                  label='Presença de CL'
                  options={this.props.clD0}
                  value={this.state.clD0 || null}
                  onChange={this.autocompleteDefaultOnChangeSavingID('clD0')}
                  onKeyDown={this.state.clD0 && this.onEnterPress}
                  onFocus={() => this.setState({ enterSubmit: false })}
                  getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                    this.props.clD0
                  )}
                  useDefaultOptionSelected
                />
              </div>

              {this.state.focusDate ? (
                <div className='grid-item p-12 p-offset-3 t-6 d-3'>
                  <DateField
                    id='dataPartoIndividual'
                    label='Data do parto individual'
                    value={this.state.dataPartoIndividual || null}
                    onChange={(e) => this.setState({ dataPartoIndividual: e })}
                    onBlur={() => {
                      if (!this.state.dataPartoIndividual) {
                        this.setState({ focusDate: false })
                      }
                    }}
                    onKeyDown={this.onEnterPress}
                    onClickCaptureButton={() =>
                      this.setState({ dataPartoIndividual: new Date() })
                    }
                    error={
                      !this.isDateNullOrValid(this.state.dataPartoIndividual)
                    }
                  />
                </div>
              ) : (
                <div className='grid-item p-12 p-offset-3 t-6 d-3'>
                  <DateField
                    initialValues={[
                      this.state.dataPartosInicio,
                      this.state.dataPartosFim,
                    ]}
                    label='Data do parto'
                    onFocus={() => this.setState({ focusDate: true })}
                    ranged
                  />
                </div>
              )}

              <div className='grid-item p-12 t-12 d-4'>
                <TextField
                  id='observacaoD0'
                  label='Observação'
                  onChange={(e) => {
                    this.textfieldDefaultOnChange(e)
                    this.setState({ enterSubmit: true })
                  }}
                  onKeyDown={this.onEnterPress}
                  value={this.state.observacaoD0 || null}
                  error={!_.isEmpty(this.state.observacaoD0_error)}
                  helperText={this.state.observacaoD0_error}
                />
              </div>

              <div className='grid-item p-12 p-padding-vertical-3'>
                <hr />
              </div>

              <div className='grid-item p-12 p-offset-3 t-6 d-2'>
                <Autocomplete
                  id='idade_Nov_Pri'
                  options={this.props.idadeMatriz}
                  value={this.state.idade_Nov_Pri || null}
                  onChange={this.autocompleteDefaultOnChangeSavingID(
                    'idade_Nov_Pri'
                  )}
                  onKeyDown={this.state.idade_Nov_Pri && this.onEnterPress}
                  getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                    this.props.idadeMatriz
                  )}
                  onFocus={() => this.setState({ enterSubmit: false })}
                  useDefaultOptionSelected
                  label='Idade (Meses)'
                  error={!_.isEmpty(this.state.idade_Nov_Pri_error)}
                  helperText={this.state.idade_Nov_Pri_error}
                />
              </div>

              <div className='grid-item p-12 t-6 d-2'>
                <Autocomplete
                  id='racaMatriz'
                  options={this.props.racaMatriz}
                  value={this.state.racaMatriz || null}
                  onChange={this.autocompleteDefaultOnChangeSavingID(
                    'racaMatriz'
                  )}
                  onKeyDown={this.state.racaMatriz && this.onEnterPress}
                  getOptionLabel={this.autocompleteDomainValueOptionLabelSavingIDUsingDescription(
                    this.props.racaMatriz
                  )}
                  onFocus={() => this.setState({ enterSubmit: false })}
                  useDefaultOptionSelected
                  label='Raça da Matriz'
                  error={!_.isEmpty(this.state.racaMatriz_error)}
                  helperText={this.state.racaMatriz_error}
                />
              </div>

              <div className='grid-item p-12 t-6 d-2'>
                <Autocomplete
                  id='ordemMatriz'
                  options={this.props.ordemMatriz}
                  value={this.state.ordemMatriz || null}
                  onChange={this.autocompleteDefaultOnChangeSavingID(
                    'ordemMatriz'
                  )}
                  onKeyDown={this.state.ordemMatriz && this.onEnterPress}
                  getOptionLabel={this.autocompleteDomainValueOptionLabelSavingIDUsingDescription(
                    this.props.ordemMatriz
                  )}
                  onFocus={() => this.setState({ enterSubmit: false })}
                  useDefaultOptionSelected
                  label='Ordem da Matriz'
                  error={!_.isEmpty(this.state.ordemMatriz_error)}
                  helperText={this.state.ordemMatriz_error}
                />
              </div>

              <div className='grid-item p-12 p-offset-3 t-6 d-2'>
                <Autocomplete
                  id='dispositivoIntravaginal'
                  options={this.props.implantes}
                  value={this.state.dispositivoIntravaginal || null}
                  onChange={this.autocompleteDefaultOnChangeSavingID(
                    'dispositivoIntravaginal'
                  )}
                  onKeyDown={
                    this.state.dispositivoIntravaginal && this.onEnterPress
                  }
                  getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                    this.props.implantes
                  )}
                  onFocus={() => this.setState({ enterSubmit: false })}
                  useDefaultOptionSelected
                  label='Dispositivo'
                  error={!_.isEmpty(this.state.dispositivoIntravaginal_error)}
                  helperText={this.state.dispositivoIntravaginal_error}
                />
              </div>

              <div className='grid-item p-12 t-6 d-2'>
                <Autocomplete
                  id='numero_Uso_Disp'
                  options={this.props.usoImplantes}
                  value={this.state.numero_Uso_Disp || null}
                  onChange={this.autocompleteDefaultOnChangeSavingID(
                    'numero_Uso_Disp'
                  )}
                  onKeyDown={this.state.numero_Uso_Disp && this.onEnterPress}
                  getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                    this.props.usoImplantes
                  )}
                  onFocus={() => this.setState({ enterSubmit: false })}
                  useDefaultOptionSelected
                  label='Uso do Dispositivo'
                  error={!_.isEmpty(this.state.numero_Uso_Disp_error)}
                  helperText={this.state.numero_Uso_Disp_error}
                />
              </div>

              <div className='grid-item p-12 t-6 d-2'>
                <Autocomplete
                  id='responsavelId'
                  options={this.props.usuarios}
                  value={this.state.responsavelId || null}
                  onChange={this.autocompleteDefaultOnChangeSavingID(
                    'responsavelId'
                  )}
                  onKeyDown={this.state.responsavelId && this.onEnterPress}
                  getOptionLabel={this.autocompleteOptionLabelUsingCustomFieldSavingID(
                    this.props.usuarios,
                    'nomeCompleto'
                  )}
                  onFocus={() => this.setState({ enterSubmit: false })}
                  useDefaultOptionSelected
                  label='Responsável'
                  error={!_.isEmpty(this.state.responsavelId_error)}
                  helperText={this.state.responsavelId_error}
                />
              </div>

              <div className='grid-item p-12 p-display-flex p-justify-space-between'>
                <Button
                  color={COLORS.GREY}
                  label='Cancelar'
                  onClick={() => {
                    this.setState({
                      editCow: false,
                      focusDate: false,
                      dataPartoIndividual: null,
                    })
                  }}
                />

                <Button
                  label='Salvar'
                  disabled={
                    this.state.loading ||
                    !!this.state.codVaca_error ||
                    this.props.user.roles[0] ===
                    'Cliente (somente visualização)'
                  }
                  onClick={
                    this.state.editCowRessinc
                      ? this.handleEditCowRessinc
                      : this.handleEditCow
                  }
                  startIcon={<SaveIcon />}
                  containerStyle={{ textAlign: 'right' }}
                />
              </div>
            </div>
          ) : (
            <>
              {window.innerWidth > 767 ? (
                <ResponsiveTable
                  columns={this.getTableColumnsData()}
                  className='table-batch'
                  data={this.state.selectedCows}
                  noDataMessage='Ainda não existe nenhuma vaca neste lote!'
                  toDataExport={this.props}
                />
              ) : (
                <MobileCard data={this.getMobileData()} />
              )}
              {this.props.batch?.matrizBloqueada && (
                <Alert severity='error'>
                  Exclua a matriz acima para que o lote seja desbloqueado.
                </Alert>
              )}
            </>
          )}

          <Prompt
            visible={verify()}
            title='Página duplicada!'
            message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
            Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
            buttons={[
              {
                autoFocus: false,
              },
            ]}
          />

          <Prompt
            visible={this.state.showFields}
            title='Escolha os campos que deseja visualizar na exportação'
            className='hidewhenprinting'
            message={
              <div className='grid-item p-12 d-3 hidewhenprinting'>
                <Autocomplete
                  multiple
                  id='allFieldsOptionsExport'
                  defaultValue={
                    JSON.parse(
                      LocalStorageHelper.get('ProGerarSavedFieldsToExport')
                    ) ?? this.state.allFieldsOptionsExport
                  }
                  onChange={(_, value) => {
                    this.setState({
                      selectedFieldsOptionsExport: value.map((item) => item),
                    })
                    LocalStorageHelper.add(
                      'ProGerarSavedFieldsToExport',
                      JSON.stringify(value)
                    )
                  }}
                  filterSelectedOptions
                  options={this.state.allFieldsOptionsExport}
                  getOptionLabel={(option) => option}
                  style={{ width: '100%', backgroundColor: 'white' }}
                />
              </div>
            }
            leftButton={{
              label: 'Redefinir',
              onClick: () => {
                this.setState({
                  selectedFieldsOptionsExport: [],
                  showFields: false,
                })
                LocalStorageHelper.add(
                  'ProGerarSavedFieldsToExport',
                  JSON.stringify(ALL_FIELDS_TO_EXPORT)
                )
              },
            }}
            buttons={[
              {
                label: 'Fechar',
                onClick: () => this.setState({ showFields: false }),
              },
              {
                label: 'Exportar',
                onClick: () => {
                  this.handleExport(this.state.selectedFieldsOptionsExport)
                  this.setState({ showFields: false })
                },
              },
            ]}
          />

          <Prompt
            visible={this.state.deleteConfirmationOpened}
            title='Confirmar Exclusão'
            message={`Deseja realmente excluir esta matriz (${this.state.selectedCowCode})?`}
            buttons={[
              {
                label: 'Não',
                onClick: () =>
                  this.setState({ deleteConfirmationOpened: false }),
              },
              {
                label: 'Sim',
                onClick: () => this.deleteCow(this.state.selectedCow),
              },
            ]}
          />
          <Prompt
            visible={this.state.showingAlreadyReservedConfirmation}
            title='Lote já reservado'
            message={`O lote já se encontra reservado por outro usuario`}
            buttons={[
              {
                label: 'Ok',
                onClick: () =>
                  this.setState({ showingAlreadyReservedConfirmation: false }),
              },
            ]}
          />
          <Prompt
            visible={this.state.noConnection}
            title='Não é possível realizar esta ação!'
            message={`Você está sem internet no momento. Verifique sua conexão e tente novamente mais tarde.`}
            buttons={[
              {
                label: 'Ok',
                onClick: () => this.setState({ noConnection: false }),
              },
            ]}
          />
          <Prompt
            visible={this.state.reserveActionSuccess}
            title='Ação Concluída'
            message={`Clique em OK para continuar.`}
            buttons={[
              {
                label: 'Ok',
                onClick: () =>
                  this.props.history.push(
                    `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}`
                  ),
              },
            ]}
          />
        </MainContainer>
      </Container>
    )
  }
}

const propsFromDatabase = async (props) => {
  const batchIds = props.match.params.id.split(';')
  const descendentBatches = await utils.getWithParam(
    AVAILABLE_ENTITIES.BATCHES,
    'originalBatch_id',
    props.match.params.id
  )

  const lotesIds = []
  descendentBatches.map((db) => lotesIds.push(db.id))
  lotesIds.push(batchIds[0])

  const allBatches = await getBatchesByBatchIds(lotesIds)

  const batch = await utils.getElement(
    AVAILABLE_ENTITIES.BATCHES,
    props.match.params.id
  )
  const corral = await utils.getElement(
    AVAILABLE_ENTITIES.CORRALS,
    props.match.params.corralId
  )
  const farm = await utils.getElement(
    AVAILABLE_ENTITIES.FARMS,
    props.match.params.farmId
  )

  const days = await utils.getDomainValuesBy('Dias')
  const tipoResync = await utils.getDomainValuesBy('Tipo de Ressincronização')
  const manejos = await utils.getDomainValuesBy('Tipo de Manejo')
  const iatf = _.find(manejos, { valor: 'IATF' })

  const superPrecoce = _.find(tipoResync, { valor: 'Super Precoce' }).id
  const precoce = _.find(tipoResync, { valor: 'Precoce' }).id

  const relatedBatches = await utils.getWithParam(
    AVAILABLE_ENTITIES.BATCHES,
    'originalBatch_id',
    batch.id
  )

  let batchList = [batch]
  let next = _.find(relatedBatches, {
    parentBatchId: _.last(batchList).id,
  })
  while (next) {
    batchList.push(next)
    next = _.find(relatedBatches, { parentBatchId: next.id })
  }

  let currentBatch = _.last(batchList)

  const isPrecoce =
    currentBatch.tipoResync === superPrecoce ||
    currentBatch.tipoResync === precoce

  const protocol = await utils.getElement(
    AVAILABLE_ENTITIES.PROTOCOLS,
    currentBatch.protocoloId
  )
  const protocolDays = protocol
    ? _.sortBy(
      _.uniq(
        protocol.managementProtocols.map((managementProtocol) =>
          _.find(days, { id: managementProtocol.day })
        )
      ),
      [
        (dia) =>
          dia.valor.startsWith('D') ? parseInt(dia.valor.substring(1)) : 100,
      ]
    )
    : []

  const currentDay =
    protocolDays[_.findIndex(protocolDays, { id: currentBatch.dias }) + 1]

  const isLastDay = currentDay && currentDay.id === _.last(protocolDays).id
  const hasIatf =
    currentDay &&
    _.find(_.filter(protocol.managementProtocols, { day: currentDay.id }), {
      managementId: iatf.id,
    })
  const vacas = await utils.getWithParam(
    AVAILABLE_ENTITIES.D0S,
    'batch_id',
    currentBatch.id
  )

  const usuarios = await utils.getWithRelation(
    AVAILABLE_ENTITIES.USERS,
    'farms__rel__users',
    'farm_id',
    corral.fazendaId
  )

  let vacasDG = _.filter(
    await utils.getWithParam(
      AVAILABLE_ENTITIES.D0S,
      'batch_id',
      currentBatch.id
    ),
    (vaca) => !vaca.omit_from_dg_final
  )
  let currentIatf = -1
  let pastDG = false
  let pastDGFinal
  let countCowsWithDG = 0
  let countCowsWithDGFinal = 0
  _.forEach(vacas, (vaca) => {
    if (vaca.iatf > currentIatf) {
      currentIatf = vaca.iatf
    }

    if (
      !_.isEmpty(vaca.condicaoDG) ||
      !_.isEmpty(vaca.observacaoDG) ||
      vaca.observacaoDG === '[N/A]'
    ) {
      countCowsWithDG++
    }
    if (
      !_.isEmpty(vaca.condicaoDGFinal) ||
      !_.isEmpty(vaca.observacaoDGFinal) ||
      vaca.observacaoDGFinal === '[N/A]'
    ) {
      countCowsWithDGFinal++
    }
  })

  if (countCowsWithDG === vacasDG.length && vacasDG.length !== 0) {
    pastDG = true
  }
  if (countCowsWithDGFinal === vacasDG.length && vacasDG.length !== 0) {
    pastDGFinal = true
  }

  let parentPastDG = false
  let countCowsWithParentDG = 0

  if (currentBatch.parentBatchId !== null) {
    const previousCows = _.filter(
      await utils.getWithParam(
        AVAILABLE_ENTITIES.D0S,
        'batch_id',
        currentBatch.parentBatchId
      ),
      (vaca) => !vaca.omit_from_dg_final
    )
    _.forEach(previousCows, (vaca) => {
      if (
        (vaca.condicaoDG !== null && vaca.condicaoDG.length > 0) ||
        (vaca.observacaoDG !== null &&
          (vaca.observacaoDG.length > 0 || vaca.observacaoDG === '[N/A]'))
      ) {
        countCowsWithParentDG++
      }
      if (pastDG && pastDGFinal) {
        return false
      }
    })
    if (
      countCowsWithParentDG === previousCows.length &&
      previousCows.length !== 0
    ) {
      parentPastDG = true
    }
  }

  let paths = [
    {
      label: formatName(currentBatch.nomeLote, LOTE),
    },
  ]

  if (farm.retiro) {
    paths.unshift({
      route: `/farms/${farm.id}/corrals/${corral.id}`,
      label: formatName(corral.nome, RETIRO),
    })
  }
  const d0 = _.find(days, { valor: 'D0' })
  const beforeDN =
    currentIatf === 0 &&
    (_.isEmpty(currentBatch.dias) || currentBatch.dias === d0.id)

  const partidas = await utils.getWithParam(
    AVAILABLE_ENTITIES.PARTIDA,
    'farm_id',
    farm.id
  )

  const bulls = await utils.getWithParam(
    AVAILABLE_ENTITIES.BULLS_NEW,
    'id',
    Q.oneOf(partidas.map((f) => f.touroId))
  )

  const oldVacas = await utils.getWithParam(
    AVAILABLE_ENTITIES.D0S,
    'batch_id',
    props.match.params.id
  )

  let allVacas = []
  allVacas = await utils.getWithParam(
    AVAILABLE_ENTITIES.D0S,
    'batch_id',
    Q.oneOf(allBatches.map((batch) => batch.id))
  )

  allVacas = await Promise.all(
    allVacas.map(async (cow) => {
      const iatf = await utils.getWithParam(
        AVAILABLE_ENTITIES.IATFS,
        'vacaId',
        cow.id
      )

      const partida = _.find(partidas, { id: iatf[0]?.partidaId })
      const bull = !!partida && _.find(bulls, { id: partida.touroId })

      if (!!!bull && !!!partida) {
        cow.partidaName = null
      } else {
        cow.partidaName = `${!!bull ? bull.nome : ''} - ${!!partida &&
          (partida?.codigo || moment(partida?.data).format('DD/MM/YYYY') || '')
          }`
      }

      return cow
    })
  )

  const uniqueVacas = allVacas
    .filter((item) => !item.omit_from_dg_final)
    .reduce((acc, curr) => {
      const vacaExists = acc.some((v) => v.codVaca === curr.codVaca)
      if (
        !vacaExists &&
        !vacas.some((v) => v.codVaca === curr.codVaca && v.iatf === curr.iatf)
      ) {
        acc.push(curr)
      }
      return acc
    }, [])

  const selectedProtocols = await Promise.all(
    protocol.managementProtocols.map(async (selectedProtocol) => {
      const management = await utils.getElement(
        AVAILABLE_ENTITIES.DOMAIN_VALUES,
        selectedProtocol.managementId
      )
      const day = await utils.getElement(
        AVAILABLE_ENTITIES.DOMAIN_VALUES,
        selectedProtocol.day
      )
      const hormone = await utils.getElement(
        AVAILABLE_ENTITIES.DOMAIN_VALUES,
        selectedProtocol.hormoneTypeId
      )

      const dosage = await utils.getElement(
        AVAILABLE_ENTITIES.DOMAIN_VALUES,
        selectedProtocol.hormoneDosageId
      )

      return {
        management: management?.valor,
        hormone: hormone?.valor,
        dosage: dosage?.valor,
        day: day?.valor,
        dosageValue: selectedProtocol.hormoneDosageValue,
      }
    })
  )

  const periodoManejo = await utils.getDomainValuesById(
    currentBatch.periodoManejo
  )
  const progesteronaPosIATF = await utils.getDomainValuesById(
    currentBatch.progesteronaPosIATF
  )
  const vacinaReprodutivaLote = await utils.getDomainValuesById(
    currentBatch.vacinaReprodutivaLote
  )

  const dayOfWithdrawal = selectedProtocols.find(
    (protocol) => protocol.management === 'Retirada do Dispositivo'
  )?.day

  const dayOfIatf = selectedProtocols.find(
    (protocol) => protocol.management === 'IATF'
  ).day

  const withdrawalDate = moment(currentBatch.dataIatf)
    .add(parseInt(dayOfWithdrawal?.replace(/\D/g, '')), 'days')
    .format('DD/MM/YYYY')

  const iatfDate = moment(currentBatch.dataIatf)
    .add(parseInt(dayOfIatf.replace(/\D/g, '')), 'days')
    .format('DD/MM/YYYY')

  return {
    user: await getLoggedUser(),
    batch,
    requiredFields: [
      'codVaca',
      'responsavelId',
      'dispositivoIntravaginal',
      'numero_Uso_Disp',
      'racaMatriz',
      'ordemMatriz',
    ],
    currentBatch,
    currentBatchData: {
      periodoEHorarioManejo: `${periodoManejo[0].valor} ${!!currentBatch.horaManejo ? `- ${currentBatch.horaManejo}` : ''
        }`,
      protocolo: protocol.name,
      progesteronaPosIATF: progesteronaPosIATF[0].valor,
      vacinaReprodutivaLote: vacinaReprodutivaLote[0].valor,
      dayOfIatf,
      dayOfIatfNumber: parseInt(dayOfIatf.replace(/\D/g, '')),
      dayOfWithdrawal,
      d0Date: moment(currentBatch.dataIatf).format('DD/MM/YYYY'),
      withdrawalDate,
      iatfDate,
    },
    isPrecoce,
    corral,
    farm,
    usuarios: usuarios ? usuarios : [],
    allVacas,
    // vacas: await utils.getWithParam(
    //   AVAILABLE_ENTITIES.D0S,
    //   'batch_id',
    //   // props.match.params.id
    //   currentBatch.id
    // ),
    vacas:
      oldVacas.length !==
        allVacas.filter((item) => !item.omit_from_dg_final).length
        ? uniqueVacas
        : allVacas.filter((item) => !item.omit_from_dg_final),
    racaBezerro: await utils.getDomainValuesBy('Raça do bezerro'),
    sexoBezerro: await utils.getDomainValuesBy('Sexo do Bezerro'),
    idadeMatriz: _.sortBy(await utils.getDomainValuesBy('Idade da Matriz'), [
      (idade) => parseInt(idade.valor.split(' ')[0]),
    ]),
    implantes: _.sortBy(await utils.getDomainValuesBy('Dispositivo'), [
      (implant) => !implant.valor.toLowerCase().startsWith('zoetis'),
      (implant) => implant.valor.toLowerCase(),
    ]),
    usoImplantes: _.sortBy(
      await utils.getDomainValuesBy('Tipo de Implante Predominante'),
      ['valor']
    ),
    clD0: _.sortBy(await utils.getDomainValuesBy('CL'), [
      (cl) => !cl.valor.toLowerCase().startsWith('sim'),
      (cl) => !cl.valor.toLowerCase().startsWith('não'),
      (cl) => cl.valor.toLowerCase(),
    ]),
    nextDay: currentDay,
    beforeDN,
    isLastDay,
    hasIatf,
    pastDG,
    pastDGFinal,
    parentPastDG,
    iatfCount: currentIatf === -1 ? 0 : currentIatf + 1,
    paths,
    ecc: _.sortBy(await utils.getDomainValuesBy('ECC'), [
      (ecc) => parseFloat(ecc.valor),
    ]),
    ordemMatriz: await utils.getDomainValuesBy('Ordem da Matriz Predominante'),
    racaMatriz: await utils.getDomainValuesBy('Raça da Matriz predominante'),
  }
}

export default utils.withPropsFromDatabase(propsFromDatabase, withRouter(Batch))
