import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, withRouter } from 'react-router-dom'
import uuid from 'react-uuid'
import _ from 'lodash'
import { Container, Box, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'

import { utils } from '../../database'
import { getLoggedUser } from '../../redux/auth/actions'
import TitleBar from '../../components/TitleBar'
import MainContainer from '../../components/MainContainer'
import TopBar from '../../components/TopBar'
import ResponsiveTable from '../../components/ResponsiveTable'
import Button, { COLORS } from '../../components/material/Button'
import CustomSwitch from '../../components/CustomSwitch'
import * as D from './data'

const AdminAddValueDomain = (props) => {
  const [domainValue, setDomainValue] = useState([])
  const [filteredDomainValue, setFilteredDomainValue] = useState([])
  const [filterIsDomainValueFather, setFilterIsDomainValueFather] = useState(false)
  const [selectedFilteredTypeDomain, setSelectedFilteredTypeDomain] =
    useState(null)
  const [selectedFilteredDomainValue, setSelectedFilteredDomainValue] =
    useState(null)
  const [domainType, setDomainType] = useState([])
  const [actions, setActions] = useState({})
  const [formData, setFormData] = useState({
    tipoDominioId: null,
    valorDominioPaiId: null,
  })
  const [tipoDominioNome, setTipoDominioNome] = useState('')
  const [valorDominioPaiIdNome, setValorDominioPaiIdNome] = useState('')
  const history = useHistory()

  const handleSearchDomainValue = (e) => {
    const val = e.target.value

    if (_.isEmpty(val)) {
      setFilteredDomainValue(domainValue)
    } else {
      const filteredDomainValue = domainValue.filter(
        (item) =>
          item.valor?.toLowerCase().indexOf(val?.toLowerCase()) >= 0 ||
          item.tipoDominioNome?.toLowerCase().indexOf(val?.toLowerCase()) >= 0
      )

      setFilteredDomainValue(filteredDomainValue)
    }
  }

  const handleFilterWithTypeDomain = async (value) => {
    if (_.isEmpty(value)) {
      setFilteredDomainValue(domainValue)
      setSelectedFilteredTypeDomain(null)

      return
    }

    const response = await D.addtipoDominioNomeInArray(domainType, value.valor)
    setSelectedFilteredTypeDomain(value.id)

    setFilteredDomainValue(response)
  }

  const handleFilterWithDomainValue = async (value) => {
    if (_.isEmpty(value)) {
      setFilteredDomainValue(domainValue)
      setSelectedFilteredDomainValue(null)

      return
    }

    const response = domainValue.filter((item) => item.valorDominioPaiId === value.id)
    setSelectedFilteredDomainValue(value.id)

    setFilteredDomainValue(response)
  }

  const handleChange = (e) => {
    e.preventDefault()

    const { name, value } = e.target

    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleDelete = async (url, id) => {
    try {
      await D.api.delete(`${url}/${id}`, props.params)

      const { domainValue } = await D.fetchData()

      setDomainValue(domainValue)
      setFilteredDomainValue(domainValue)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmitDomainValue = async (e) => {
    e.preventDefault()

    const payload = {
      ...formData,
      ...(!formData.id && {
        id: uuid(),
      }),
    }

    try {
      await D.api[!!formData.id ? 'put' : 'post'](
        '/ValorDominio',
        payload,
        props.params
      )

      const { domainValue } = await D.fetchData()

      setDomainValue(domainValue)
      setFilteredDomainValue(domainValue)
      setFormData({ tipoDominioId: null, valorDominioPaiId: null })
      setActions({ domainValueModalAfterSubmitOrEdit: true })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setActions({})
    setFormData({
      tipoDominioId: null,
      valorDominioPaiId: null,
    })
  }, [])

  useEffect(() => {
    ; (async () => {
      const { domainValue, domainType } = await D.fetchData()

      setDomainValue(domainValue)
      setDomainType(domainType)
      setFilteredDomainValue(domainValue)
    })()
  }, [])

  return (
    <Container>
      <TopBar title='Valor Dominio' />
      <TitleBar
        title='Valor Dominio'
        buttons={[
          {
            onClick: () => history.push('/lancamentos-do-administrador'),
            label: 'Voltar',
          },
        ]}
        paths={[
          {
            route: '/lancamentos-do-administrador',
            label: 'Lançamentos do administrador',
          },
          {
            label: 'Valor Dominio',
          },
        ]}
      />
      <MainContainer>
        <div className='grid-item p-12 t-6 p-display-flex d0-grid-form-top-info-right'>
          <CustomSwitch
            onHandleChange={(e) => {
              setFilterIsDomainValueFather(e.target.checked)
              setSelectedFilteredTypeDomain(null)
              setSelectedFilteredDomainValue(null)
              setFilteredDomainValue(domainValue)
            }}
            checked={filterIsDomainValueFather}
            text='Trocar filtro para: Valor Dominio Pai'
          />
        </div>
        <form onSubmit={handleSubmitDomainValue} className='grid d0-grid-form'>
          <div className='grid-item p-12 d-3'>
            <TextField
              id='valor'
              label='Valor'
              name='valor'
              variant='outlined'
              type='text'
              required
              autoComplete='off'
              style={{ width: '100%', backgroundColor: 'white' }}
              onChange={handleChange}
              value={formData.valor || ''}
            />
          </div>
          <div className='grid-item p-12 d-3'>
            <TextField
              id='descricao'
              label='Descrição'
              name='descricao'
              variant='outlined'
              type='text'
              autoComplete='off'
              style={{ width: '100%', backgroundColor: 'white' }}
              onChange={handleChange}
              value={formData.descricao || ''}
            />
          </div>
          <div className='grid-item p-12 d-3'>
            <Autocomplete
              style={{ width: '100%', backgroundColor: 'white' }}
              onChange={(e, value) => {
                console.log('tipoDominioId onChange', value)
                if (!value) {
                  setFormData((prev) => ({
                    ...prev,
                    tipoDominioId: null,
                    tipoDominioNome: '',
                  }))
                  setTipoDominioNome('')

                  return
                }

                setFormData((prev) => ({ ...prev, tipoDominioId: value.id }))
                setTipoDominioNome(value.nome)
              }}
              value={formData.tipoDominioId || null}
              inputValue={tipoDominioNome || formData.tipoDominioNome || ''}
              onInputChange={(e, value) => {
                if (!_.isEmpty(value)) {
                  setTipoDominioNome(value)
                } else if (tipoDominioNome.length === 1 && _.isEmpty(value)) {
                  setTipoDominioNome('')
                }
              }}
              id='tipoDominioId'
              options={domainType}
              filterSelectedOptions
              getOptionSelected={(option, value) => option.id === value}
              getOptionLabel={(option) => (option.nome ? option.nome : '')}
              renderInput={(params) => (
                <TextField
                  autoComplete='off'
                  {...params}
                  label='Tipo Dominio'
                  variant='outlined'
                />
              )}
            />
          </div>
          <div className='grid-item p-12 d-3'>
            <Autocomplete
              style={{ width: '100%', backgroundColor: 'white' }}
              onChange={(e, value) => {
                if (!value) {
                  setFormData((prev) => ({
                    ...prev,
                    valorDominioPaiId: null,
                    valorDominioPaiIdNome: '',
                  }))
                  setValorDominioPaiIdNome('')

                  return
                }

                setFormData((prev) => ({
                  ...prev,
                  valorDominioPaiId: value.id,
                }))
                setValorDominioPaiIdNome(value.valor)
              }}
              value={formData.valorDominioPaiId || null}
              inputValue={
                valorDominioPaiIdNome || formData.valorDominioPaiIdNome || ''
              }
              onInputChange={(e, value) => {
                if (!_.isEmpty(value)) {
                  setValorDominioPaiIdNome(value)
                } else if (
                  valorDominioPaiIdNome.length === 1 &&
                  _.isEmpty(value)
                ) {
                  setValorDominioPaiIdNome('')
                }
              }}
              id='valorDominioPaiId'
              options={domainValue}
              filterSelectedOptions
              getOptionSelected={(option, value) => option.id === value}
              getOptionLabel={(option) => (option.valor ? option.valor : '')}
              renderInput={(params) => (
                <TextField
                  autoComplete='off'
                  {...params}
                  label='Valor Dominio Pai'
                  variant='outlined'
                />
              )}
            />
          </div>
          <div className='grid-item p-12 p-display-flex p-justify-space-between'>
            <Button
              color={COLORS.GREY}
              label={!!formData.id ? 'Cancelar' : 'Limpar'}
              type='button'
              startIcon={!!!formData.id && <DeleteIcon />}
              onClick={() => {
                setFormData({
                  tipoDominioId: null,
                  valorDominioPaiId: null,
                })
                setTipoDominioNome('')
                setValorDominioPaiIdNome('')
                setActions({})
              }}
            />

            <Button
              label='Salvar'
              type='submit'
              startIcon={<SaveIcon />}
              containerStyle={{ textAlign: 'right' }}
            />
          </div>
        </form>

        {!actions.domainValueEdit && (
          <MainContainer
            hasSearchWithFilter
            searchLabel='Buscar'
            filterProps={filterIsDomainValueFather ? domainValue : domainType}
            defaultFilterValue={
              filterIsDomainValueFather
                ? selectedFilteredDomainValue
                : selectedFilteredTypeDomain
            }
            filterLabel={
              filterIsDomainValueFather ? 'Valor Dominio Pai' : 'Tipo Dominio'
            }
            handleSearch={handleSearchDomainValue}
            handleFilter={
              filterIsDomainValueFather
                ? handleFilterWithDomainValue
                : handleFilterWithTypeDomain
            }
            customStyle={{ marginTop: 0, paddingTop: 0 }}
          >
            <Box display='flex'>
              <ResponsiveTable
                hasTopPagination={false}
                columns={D.getDomainValueTableData(setActions, setFormData)}
                rowsPerPage={20}
                data={filteredDomainValue}
              />
            </Box>
          </MainContainer>
        )}
      </MainContainer>

      {D.getModals(actions, setActions, formData, handleDelete)}
    </Container>
  )
}

const propsFromDatabase = async () => {
  const { token } = await getLoggedUser()
  const params = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return {
    params,
  }
}

export default connect()(
  utils.withPropsFromDatabase(
    propsFromDatabase,
    withRouter(AdminAddValueDomain)
  )
)
