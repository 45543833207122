export const updateNotes = {
  title: 'O que há de novo?',
  text: [
    'Agora é possível escolher diferentes tipos de exportação (Geral, Personalizada e POWER BI);',
    'Agora a sua configuração de salvar relatórios e de exportação personalizada fica salva',
    'Agora é possível voltar para uma ressinc anterior!;',
    'Agora é possível editar as matrizes após a Ressinc!;',
    'Agora é possível fazer lançamanetos de manejos a partir de uma planilha excel!;',
  ],
}

export const reportExportWarning = {
  title: 'Aviso!',
  text: 'Novas informações referente ao manejo estarão disponíveis no relatório de exportação em algumas horas',
}

export const changeCowsToBatchNotes = {
  title: 'Deseja mover esta matriz para este lote?',
  text: 'Ao realizar esta ação a matriz será transferida para este lote.',
}

export const duplicatePage = {
  title: 'Página duplicada!',
  text: 'Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! Para continuar utilizando, feche esta página e volte para utilizar a anterior.',
}
