import { AVAILABLE_ENTITIES, Repository, utils } from '../../../database'
import Autocomplete, {
  autocompleteDefaultOnChange,
  autocompleteDefaultOnChangeSavingID,
  autocompleteDefaultOnChangeSavingIDWhenOpenField,
  autocompleteDomainValueOptionLabelSavingID,
  autocompleteOptionLabelUsingCustomFieldSavingIDWhenOpenField,
} from './../../../components/material/Autocomplete'
import Button, { COLORS } from './../../../components/material/Button'
import {
  REQUIRED_FIELD_MESSAGES,
  isDateNullOrValid,
  missingRequiredFieldChecker,
  requiredFieldsEvaluator,
} from './../../../utils/formHelper'
import React, { Component } from 'react'
import ResponsiveTable, {
  CELL_TYPES,
} from '../../../components/ResponsiveTable'
import TextField, {
  textfieldDefaultOnChange,
  textfieldOnChangeWithCustomMessage,
} from './../../../components/material/TextField'

import AddIcon from '@material-ui/icons/Add'
import Container from '@material-ui/core/Container'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import FormHelperText from '@material-ui/core/FormHelperText'
import MainContainer from '../../../components/MainContainer'
import Prompt from '../../../components/Prompt'
import { Q } from '@nozbe/watermelondb'
import TitleBar from '../../../components/TitleBar'
import TopBar from '../../../components/TopBar'
import TrainingsButton from '../../Trainings/TrainingsButton'
import _ from 'lodash'
import { datefieldDefaultOnChange } from './../../../components/material/DateField'
import track from 'react-tracking'
import { withRouter } from 'react-router-dom'

@track(() => ({ page: 'Sêmen', date: new Date() }))
class BullFormForAll extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props.initialValues,
      central_value: props.initialValues && props.initialValues.centralValue,
      farms: this.props.farms,
      bulls: [],
      deleteConfirmationOpened: false,
      deleteCannotModalOpened: false,
      disabledBull: [],
      selectedBull: null,
      toEditBull: null,
      toEditNome: null,
      toEditRaca: null,
      toEditCentral: null,
      toEditObservacao: null,
      toEditCod_Touro: null,
      selectedData: [],
      createdConfirmation: false,
      lastIdSavedOrEdited: '',
    }

    this.onCancel = this.onCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.autocompleteDefaultOnChangeSavingID =
      autocompleteDefaultOnChangeSavingID.bind(this)
    this.getTableColumnsData = this.getTableColumnsData.bind(this)
    this.autocompleteDefaultOnChange = autocompleteDefaultOnChange.bind(this)
    this.autocompleteDomainValueOptionLabelSavingID =
      autocompleteDomainValueOptionLabelSavingID.bind(this)
    this.datefieldDefaultOnChange = datefieldDefaultOnChange.bind(this)
    this.textfieldDefaultOnChange = textfieldDefaultOnChange.bind(this)
    this.textfieldOnChangeWithCustomMessage =
      textfieldOnChangeWithCustomMessage.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.missingRequiredFieldChecker = missingRequiredFieldChecker.bind(this)
    this.requiredFieldsEvaluator = requiredFieldsEvaluator.bind(this)

    this.autocompleteDefaultOnChangeSavingIDWhenOpenField =
      autocompleteDefaultOnChangeSavingIDWhenOpenField.bind(this)
    this.autocompleteOptionLabelUsingCustomFieldSavingIDWhenOpenField =
      autocompleteOptionLabelUsingCustomFieldSavingIDWhenOpenField.bind(this)
    this.isDateNullOrValid = isDateNullOrValid.bind(this)

    this.getBulls()
  }

  onCancel() {
    this.props.history.push(`/farms`)
  }

  onEnterPress = (e) => {
    if (this.props.user.roles[0].name !== 'Cliente (somente visualização)') {
      if (e.keyCode == 13 && e.shiftKey == false) {
        e.preventDefault()
        this.handleSubmit()
      }
    }
  }

  getButtons() {
    return [
      {
        onClick: () => this.backLocation(),
        label: `Voltar ${this.props.management != 'none' ? 'ao manejo' : ''}`,
      },
    ]
  }

  @track((props) => ({
    action: `Voltou para ${props.management != 'none' ? 'o manejo' : 'home'}`,
  }))
  backLocation() {
    if (this.props.batchId && this.props.batchId.length > 1) {
      this.props.history.push(
        `/${this.props.management}/${this.props.batchId
          .map((id) => id)
          .join(';')}/redirected/true`
      )
    } else if (
      this.props.batchId != 'none' &&
      this.props.management != 'none'
    ) {
      this.props.history.push(
        `/${this.props.management}/${this.props.batchId}/redirected/true`
      )
    } else {
      this.props.history.push(`/`)
    }
  }

  async handleModalToDelete(bull) {
    const partidas = await utils.getWithParam(
      AVAILABLE_ENTITIES.PARTIDA,
      'bull_id',
      bull.id
    )

    const iatfs = await utils.getWithParam(
      AVAILABLE_ENTITIES.IATFS,
      'bull_id',
      Q.oneOf(partidas.filter((item) => item.fazendaId).map((item) => item.id))
    )

    if (iatfs.length === 0) {
      this.setState({ deleteConfirmationOpened: true })
    } else {
      this.setState({
        deleteCannotModalOpened: true,
        disabledBull: [...this.state.disabledBull, bull.id],
      })
    }
  }

  @track((props, state) => ({
    action: 'Editou um Sêmen',
    value: state.lastIdSavedOrEdited,
  }))
  async handleEdit() {
    // const bullsRepository = new Repository(AVAILABLE_ENTITIES.BULLS)
    const bullsRepository = new Repository(AVAILABLE_ENTITIES.BULLS_NEW)
    const bulls = await bullsRepository.get()
    if (bulls.success) {
      let relatedBulls = bulls.response.filter(
        (x) =>
          x.usuarioId &&
          (x.id == this.state.toEditBull.id ||
            x.touroOriginalId == this.state.toEditBull.id)
      )

      let payload = {
        nome: this.state.toEditNome,
        central: this.state.toEditCentral,
        raca: this.state.toEditRaca,
        observacao: this.state.toEditObservacao,
        fazendaId: null,
        cod_Touro: this.state.toEditCod_Touro,
      }

      for (let relatedBull of relatedBulls) {
        let requiredFieldsState = this.requiredFieldsEvaluator()
        const requiredFieldsEdit = {
          ...(requiredFieldsState.toEditNome_error && {
            toEditNome_error: requiredFieldsState.toEditNome_error,
          }),
          ...(requiredFieldsState.toEditRaca_error && {
            toEditRaca_error: requiredFieldsState.toEditRaca_error,
          }),
          ...(requiredFieldsState.toEditCentral_error && {
            toEditCentral_error: requiredFieldsState.toEditCentral_error,
          }),
        }

        if (Object.keys(requiredFieldsEdit).length !== 0) {
          this.setState(requiredFieldsEdit)
        } else {
          let updateRequest = await bullsRepository.update({
            id: relatedBull.id,
            ...payload,
          })
          if (updateRequest.success) {
            console.log(`Update of bull successful`)
            this.setState({
              toEditBull: null,
              toEditNome: null,
              toEditCentral: null,
              toEditRaca: null,
              toEditObservacao: null,
              toEditCod_Touro: null,
              lastIdSavedOrEdited: updateRequest.response.id,
            })
          } else {
            console.log(`Update of bull failed: ${updateRequest.exception}`)
          }
        }
      }
    }

    this.getBulls()
  }

  @track((props, state, bull) => ({
    action: 'Deletou um Sêmen',
    value: bull[0],
  }))
  async handleDelete(selectedBull) {
    // const repository = new Repository(AVAILABLE_ENTITIES.BULLS)
    const repository = new Repository(AVAILABLE_ENTITIES.BULLS_NEW)
    const repositoryBulls = await repository.get()
    const bulls = repositoryBulls.response.filter(
      (b) => b.touroOriginalId === selectedBull
    )

    for (let bull of bulls) {
      const bullsRequest = await repository.delete(bull.id)
      if (bullsRequest.success) {
        console.log(`Delete of bull successful`)
      } else {
        console.log(`Delete of bull failed: ${bullsRequest.exception}`)
      }
    }

    const bullRequest = await repository.delete(selectedBull)
    if (bullRequest.success) {
      console.log(`Delete of bull successful`)
    } else {
      console.log(`Delete of bull failed: ${bullRequest.exception}`)
    }

    this.getBulls()
    this.setState({
      deleteConfirmationOpened: false,
    })
  }

  handleSearch(e) {
    const val = e.target.value
    if (_.isEmpty(val)) {
      this.setState({ selectedData: this.state.bulls })
    } else {
      const filteredBulls = this.state.bulls.filter(
        (x) => x['nome'].toLowerCase().indexOf(val.toLowerCase()) >= 0
      )
      this.setState({ selectedData: filteredBulls })
    }
  }

  @track((props, state, bull) => ({
    action: 'Entrou na tela de cadastro de Partida',
    value: bull[0].id,
  }))
  handleEntryInPageToPartidas = (row) => {
    this.props.batchId && this.props.batchId.length > 1
      ? this.props.history.push(
        `/partidas/${row.id}/batch/${this.props.batchId
          .map((id) => id)
          .join(';')}/management/${this.props.management}`
      )
      : this.props.history.push(
        `/partidas/${row.id}/batch/${this.props.batchId}/management/${this.props.management}`
      )
  }

  getTableColumnsData() {
    return [
      {
        name: 'Touro',
        type: CELL_TYPES.LINK,
        label: (row) => row.nome,
        onClick: (row) => () => this.handleEntryInPageToPartidas(row),
      },
      {
        name: 'Código do Touro',
        type: CELL_TYPES.TEXT,
        label: (row) => row.cod_Touro,
      },
      {
        name: 'Raça',
        type: CELL_TYPES.TEXT,
        label: (row) =>
          this.props.racas.filter((f) => f.id == row.raca)[0]?.valor,
      },
      {
        name: 'Central',
        type: CELL_TYPES.TEXT,
        label: (row) =>
          this.props.centrais.filter((f) => f.id == row.central)[0]?.valor,
      },
      {
        name: 'Observação',
        type: CELL_TYPES.TEXT,
        label: (row) => row.observacao,
      },
      {
        type: CELL_TYPES.BUTTON_ARR,
        buttons: (row) =>
          _.compact([
            {
              icon: <AddIcon style={{ fontSize: '28px', padding: '0' }} />,
              label: 'Cadastrar partida',
              onClick: () => this.handleEntryInPageToPartidas(row),
            },
            {
              icon: <EditIcon />,
              label: 'Editar',
              onClick: () => {
                this.setState({
                  toEditBull: row,
                  toEditNome: row.nome,
                  toEditRaca: row.raca,
                  toEditCentral: row.central,
                  toEditObservacao: row.observacao,
                  toEditCod_Touro: row.cod_Touro,
                })
              },
            },
            {
              icon: <DeleteIcon />,
              label: 'Excluir',
              onClick: () => {
                this.handleModalToDelete(row)
                this.setState({
                  selectedBull: row.id,
                })
              },
              disabled: this.state.disabledBull.includes(row.id) || this.props.user.roles[0].name === 'Cliente (somente visualização)',
            },
          ]),
      },
    ]
  }

  async getBulls() {
    // const bullsRepository = new Repository(AVAILABLE_ENTITIES.BULLS)
    const bullsRepository = new Repository(AVAILABLE_ENTITIES.BULLS_NEW)
    //TODO: Change login info source after offline login is implemented
    const bulls = await bullsRepository.get()

    if (bulls.success) {
      let formatedBulls = bulls.response.filter(
        // x => x.usuarioId && !x.fazendaId && !x.partidaOriginalId && !x.touroOriginalId
        (x) => x.usuarioId && !x.partidaOriginalId && !x.touroOriginalId
      )

      this.setState({ bulls: formatedBulls })
      this.setState({ selectedData: this.state.bulls })
    }
  }

  getTableData() {
    return this.state.bulls
  }

  @track((props, state) => ({
    action: 'Salvou um novo Sêmen',
    value: state.lastIdSavedOrEdited,
  }))
  async handleSubmit() {
    let payload = {
      ...this.props.bull,
      ..._.pick(this.state, [
        'nome',
        'raca',
        'central',
        'observacao',
        'cod_Touro',
      ]),
      // fazendaId: null,
      usuarioId: this.props.user.id,
      centralValue: this.state.central_value,
    }

    let requiredFieldsState = this.requiredFieldsEvaluator()
    const requiredFieldsCreate = {
      ...(requiredFieldsState.nome_error && {
        nome_error: requiredFieldsState.nome_error,
      }),
      ...(requiredFieldsState.raca_error && {
        raca_error: requiredFieldsState.raca_error,
      }),
      ...(requiredFieldsState.central_error && {
        central_error: requiredFieldsState.central_error,
      }),
    }
    let validDates = this.isDateNullOrValid(this.state.partida)

    if (Object.keys(requiredFieldsCreate).length !== 0 || !validDates) {
      this.setState(requiredFieldsCreate)
    } else {
      // const repository = new Repository(AVAILABLE_ENTITIES.BULLS)
      const repository = new Repository(AVAILABLE_ENTITIES.BULLS_NEW)
      const createRequest = await repository.post(payload)
      if (createRequest.success) {
        console.log('Bull created successfully')
        this.setState({
          nome: null,
          raca: null,
          central: null,
          central_value: null,
          observacao: null,
          cod_Touro: null,
          lastIdSavedOrEdited: createRequest.response.id,
        })
        await this.getBulls()
        this.setState({ createdConfirmation: true })
      } else {
        console.log(
          `There was an error creating bull: ${createRequest.exception}`
        )
      }
    }
  }

  render() {
    return (
      <Container>
        <TrainingsButton
          page={
            this.props.management[0] !== 'none'
              ? `/bulls/create-for-all/batch/${this.props.batchId[0]}/management/${this.props.management[0]}`
              : '/bulls/create-for-all/batch/none/management/none'
          }
        />
        <TopBar
          title={
            !this.state.toEditBull
              ? `Novo Sêmen`
              : `Editar Sêmen: ${this.state.toEditBull.nome}`
          }
        />
        <TitleBar
          title={
            !this.state.toEditBull
              ? `Novo Sêmen`
              : `Editar Sêmen: ${this.state.toEditBull.nome}`
          }
          paths={[
            {
              route: `/farms`,
              label: 'Fazendas',
            },
            {
              label: !this.state.toEditBull
                ? `Novo Sêmen`
                : `Editar Sêmen: ${this.state.toEditBull.nome}`,
            },
          ]}
          buttons={this.getButtons()}
        />

        <MainContainer
          containerTitle={
            !this.state.toEditBull
              ? `Novo Sêmen`
              : `Editar Sêmen: ${this.state.toEditBull.nome}`
          }
        >
          <div>
            {!this.state.toEditBull ? (
              <div className='grid bull-grid-form'>
                <div className='grid-item p-12 t-4'>
                  <TextField
                    id='nome'
                    label='Touro'
                    onChange={this.textfieldDefaultOnChange}
                    onKeyDown={this.onEnterPress}
                    value={this.state.nome}
                    error={!_.isEmpty(this.state.nome_error)}
                    helperText={this.state.nome_error}
                    disabled={this.props.viewOnly}
                    autoFocus
                  />
                </div>

                <div className='grid-item p-12 t-4'>
                  <TextField
                    id='cod_Touro'
                    label='Código do Touro'
                    onChange={this.textfieldDefaultOnChange}
                    onKeyDown={this.onEnterPress}
                    value={this.state.cod_Touro}
                    disabled={this.props.viewOnly}
                    autoFocus
                  />
                  <FormHelperText id='outlined-weight-helper-text'>
                    Preencher com código NAAB ou registro do touro.
                  </FormHelperText>
                </div>

                <div className='grid-item p-12 t-4'>
                  <Autocomplete
                    id='raca'
                    options={this.props.racas}
                    value={this.state.raca}
                    onChange={this.autocompleteDefaultOnChangeSavingID('raca')}
                    // onKeyDown={this.state.raca && this.onEnterPress }
                    getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                      this.props.racas
                    )}
                    useDefaultOptionSelected
                    label='Raça'
                    error={!_.isEmpty(this.state.raca_error)}
                    helperText={this.state.raca_error}
                    disabled={this.props.viewOnly}
                  />
                </div>

                <div className='grid-item p-12 t-4'>
                  <Autocomplete
                    id='central'
                    options={this.props.centrais}
                    value={this.state.central || this.state.central_value || ''}
                    onChange={this.autocompleteDefaultOnChangeSavingIDWhenOpenField(
                      'central'
                    )}
                    onKeyDown={this.state.central && this.onEnterPress}
                    getOptionLabel={this.autocompleteOptionLabelUsingCustomFieldSavingIDWhenOpenField(
                      this.props.centrais
                    )}
                    useDefaultOptionSelected
                    label='Central'
                    error={!_.isEmpty(this.state.central_error)}
                    helperText={this.state.central_error}
                    disabled={this.props.viewOnly}
                    openField
                  />
                </div>

                <div className='grid-item p-12 t-8'>
                  <TextField
                    id='observacao'
                    label='Observação'
                    onChange={this.textfieldOnChangeWithCustomMessage(
                      REQUIRED_FIELD_MESSAGES.ONE_OF_MANY
                    )}
                    onKeyDown={this.onEnterPress}
                    value={this.state.observacao}
                    error={!_.isEmpty(this.state.observacao_error)}
                    helperText={this.state.observacao_error}
                    disabled={this.props.viewOnly}
                  />
                </div>

                <div className='grid-item p-12 p-display-flex p-justify-space-between'>
                  <Button
                    color={COLORS.GREY}
                    label={this.props.viewOnly ? 'Voltar' : 'Cancelar'}
                    onClick={this.onCancel}
                  />
                  {!this.props.viewOnly && (
                    <Button
                      label='Salvar'
                      onClick={() => this.handleSubmit()}
                      disabled={this.props.user.roles[0].name === 'Cliente (somente visualização)'}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div className='grid bull-grid-form'>
                <div className='grid-item p-12 t-4'>
                  <TextField
                    id='toEditNome'
                    label='Touro'
                    onChange={(e) =>
                      this.setState({ toEditNome: e.target.value })
                    }
                    onKeyDown={this.onEnterPress}
                    value={this.state.toEditNome || null}
                    error={!_.isEmpty(this.state.toEditNome_error)}
                    helperText={this.state.toEditNome_error}
                    disabled={this.props.viewOnly}
                    autoFocus
                  />
                </div>

                <div className='grid-item p-12 t-4'>
                  <TextField
                    id='toEditCod_Touro'
                    label='Código do Touro'
                    onChange={(e) =>
                      this.setState({ toEditCod_Touro: e.target.value })
                    }
                    onKeyDown={this.onEnterPress}
                    value={this.state.toEditCod_Touro || null}
                    disabled={this.props.viewOnly}
                    autoFocus
                  />
                  <FormHelperText id='outlined-weight-helper-text'>
                    Preencher com código NAAB ou registro do touro.
                  </FormHelperText>
                </div>

                <div className='grid-item p-12 t-4'>
                  <Autocomplete
                    id='toEditRaca'
                    options={this.props.racas}
                    value={this.state.toEditRaca}
                    onChange={this.autocompleteDefaultOnChangeSavingID(
                      'toEditRaca'
                    )}
                    onKeyDown={this.state.toEditRaca && this.onEnterPress}
                    getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                      this.props.racas
                    )}
                    useDefaultOptionSelected
                    label='Raça'
                    error={!_.isEmpty(this.state.toEditRaca_error)}
                    helperText={this.state.toEditRaca_error}
                    disabled={this.props.viewOnly}
                  />
                </div>

                <div className='grid-item p-12 t-4'>
                  <Autocomplete
                    id='toEditCentral'
                    options={this.props.centrais}
                    value={
                      this.state.toEditCentral ||
                      this.state.toEditCentral_value ||
                      ''
                    }
                    onChange={this.autocompleteDefaultOnChangeSavingIDWhenOpenField(
                      'toEditCentral'
                    )}
                    onKeyDown={this.state.toEditCentral && this.onEnterPress}
                    getOptionLabel={this.autocompleteOptionLabelUsingCustomFieldSavingIDWhenOpenField(
                      this.props.centrais
                    )}
                    useDefaultOptionSelected
                    label='Central'
                    error={!_.isEmpty(this.state.toEditCentral_error)}
                    helperText={this.state.toEditCentral_error}
                    disabled={this.props.viewOnly}
                    openField
                  />
                </div>

                <div className='grid-item p-12 t-8'>
                  <TextField
                    id='toEditObservacao'
                    label='Observação'
                    onChange={(e) =>
                      this.setState({ toEditObservacao: e.target.value })
                    }
                    onKeyDown={this.onEnterPress}
                    value={this.state.toEditObservacao}
                    error={!_.isEmpty(this.state.toEditObservacao_error)}
                    helperText={this.state.toEditObservacao_error}
                    disabled={this.props.viewOnly}
                  />
                </div>

                <div className='grid-item p-12 p-display-flex p-justify-space-between'>
                  <Button
                    color={COLORS.GREY}
                    label={this.props.viewOnly ? 'Voltar' : 'Cancelar'}
                    onClick={() =>
                      this.setState({
                        toEditBull: null,
                        toEditNome: null,
                        toEditCentral: null,
                        toEditRaca: null,
                        toEditObservacao: null,
                        toEditCod_Touro: null,
                      })
                    }
                  />
                  {!this.props.viewOnly && (
                    <Button label='Salvar' onClick={() => this.handleEdit()} disabled={this.props.user.roles[0].name === 'Cliente (somente visualização)'} />
                  )}
                </div>
              </div>
            )}

            {!this.state.toEditBull && (
              <MainContainer
                hasSearch
                searchLabel='Buscar'
                handleSearch={this.handleSearch}
                noDataMessage='Ainda não existe nenhum touro cadastrado!'
              >
                <ResponsiveTable
                  columns={this.getTableColumnsData()}
                  data={this.state.selectedData.sort((a, b) =>
                    a.nome.localeCompare(b.nome)
                  )}
                  className='table-partidas'
                />
              </MainContainer>
            )}

            <Prompt
              visible={this.state.createdConfirmation}
              title='Sucesso!'
              message='Para que o touro esteja disponível na tela de IATF, é necessário o cadastro de partida para as fazendas desejadas.'
              buttons={[
                {
                  label: 'Ok',
                  onClick: () => this.setState({ createdConfirmation: false }),
                },
              ]}
            />

            <Prompt
              visible={this.state.deleteConfirmationOpened}
              title='Confirmar Exclusão'
              message='Você está prestes a deletar este touro. Esta ação não poderá ser desfeita.'
              buttons={[
                {
                  label: 'Cancelar',
                  onClick: () =>
                    this.setState({ deleteConfirmationOpened: false }),
                },
                {
                  label: 'Confirmar',
                  onClick: () => this.handleDelete(this.state.selectedBull),
                },
              ]}
            />

            <Prompt
              visible={this.state.deleteCannotModalOpened}
              title='Não é possível excluir este touro'
              message='Parece que este touro já possui IATFs cadastradas. Para excluir este touro, é necessário excluir as IATFs relacionadas a ele.'
              buttons={[
                {
                  label: 'Ok',
                  onClick: () =>
                    this.setState({ deleteCannotModalOpened: false }),
                },
              ]}
            />
          </div>
        </MainContainer>
      </Container>
    )
  }
}

const propsFromDatabase = async (props) => {
  const allRacas = await utils.getDomainValuesBy('Raça do Touro')
  let racas = []

  const aberdeen = _.find(allRacas, (raca) =>
    raca.valor.toLowerCase().includes('aberdeen angus')
  )
  const nelore = _.find(
    allRacas,
    (raca) => raca.valor.toLowerCase() === 'nelore'
  )
  const braford = _.find(allRacas, (raca) =>
    raca.valor.toLowerCase().includes('braford')
  )
  const brangus = _.find(allRacas, (raca) =>
    raca.valor.toLowerCase().includes('brangus')
  )
  const hereford = _.find(allRacas, (raca) =>
    raca.valor.toLowerCase().includes('hereford')
  )

  racas = _.compact([aberdeen, nelore, braford, brangus, hereford])
  const ids = racas.map((raca) => raca.id)

  racas = _.compact(
    racas.concat(
      _.sortBy(
        _.filter(allRacas, (raca) => !_.find(ids, (id) => id === raca.id)),
        [(raca) => raca.valor.toLowerCase()]
      )
    )
  )

  return {
    racas,
    requiredFields: [
      'nome',
      'raca',
      'central',
      'toEditNome',
      'toEditRaca',
      'toEditCentral',
    ],
    centrais: _.sortBy(await utils.getDomainValuesBy('Centrais'), [
      (central) => central.valor.toLowerCase(),
    ]),
  }
}

export default utils.withPropsFromDatabase(
  propsFromDatabase,
  withRouter(BullFormForAll)
)
