import * as api from '../../redux/api/actions.js'

import React, { Component } from 'react'
import ResponsiveTable, { CELL_TYPES } from '../../components/ResponsiveTable'
import { sortList } from '../../utils/helper'

import AddIcon from '@material-ui/icons/Add'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import EditIcon from '@material-ui/icons/Edit'
import MainContainer from '../../components/MainContainer'
import Prompt from '../../components/Prompt.js'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import _ from 'lodash'
import { connect } from 'react-redux'
import { verify } from './../../utils/verifyDuplicatePages'
import { withRouter } from 'react-router-dom'
import track from 'react-tracking'
import TrainingsButton from '../Trainings/TrainingsButton'

@track(() => ({ page: 'Usuários List', date: new Date() }))
class UserList extends Component {
  constructor(props) {
    super(props)

    const sortedUsers =
      props.users && props.users.length > 0
        ? sortList(props.users, 'nomeCompleto')
        : []
    this.state = {
      users: sortedUsers,
      orderedUsers: sortedUsers
    }

    this.handleSearch = this.handleSearch.bind(this)
    this.props.list()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.users &&
      this.props.users.length > 0 &&
      prevProps.users.length !== this.props.users.length
    ) {
      const sortedUsers = _.sortBy(this.props.users, [
        user => user.nomeCompleto?.toLowerCase()
      ])

      this.setState({
        users: sortedUsers,
        orderedUsers: sortedUsers
      })
    }
  }

  componentDidMount() { }

  handleSearch(e) {
    const val = e.target.value
    this.setState({
      users: _.isEmpty(val)
        ? this.state.orderedUsers
        : this.state.orderedUsers.filter(
          x => x.nomeCompleto?.toLowerCase().indexOf(val?.toLowerCase()) >= 0
        )
    })
  }

  @track((props, state, user) => ({ action: 'Clicou no botao de Editar usuário', value: user[0].id }))
  handleEditUser = (row) => this.props.history.push(`/users/${row.id}`)

  getTableColumnsData() {
    return [
      {
        name: 'Nome Completo',
        type: CELL_TYPES.TEXT,
        label: row => row.nomeCompleto
      },
      {
        name: 'Município/UF',
        type: CELL_TYPES.TEXT,
        label: row =>
          `${row.municipio && row.estado
            ? `${row.municipio}/${row.estado}`
            : ''
          }`
      },
      {
        name: 'E-mail',
        type: CELL_TYPES.TEXT,
        label: row => row.email.indexOf('@usuariosememail.com') >= 0 ? 'Não Possui' : row.email
      },
      {
        type: CELL_TYPES.ICON_BUTTON,
        icon: row => <EditIcon style={{ fontSize: 23 }} />,
        label: row => 'Editar',
        onClick: row => () => this.handleEditUser(row)
      }
    ];
  }

  @track({ action: 'Entrou na página de novo usuário' })
  handleNewUser = () => this.props.history.push('/users/create/')

  render() {
    if (this.props.loading) {
      return (
        <Backdrop open>
          <CircularProgress color='inherit' />
        </Backdrop>
      )
    }

    return (
      <Container className="page-users">
        <TrainingsButton page='/users' />
        <TopBar title='' />
        <TitleBar
          title='Usuários'
          buttons={[
            {
              onClick: this.handleNewUser,
              icon: <AddIcon />,
              label: 'Novo Usuário'
            }
          ]}
          paths={[
            {
              label: 'Usuários'
            }
          ]}
        />
        <MainContainer
          hasSearch
          searchLabel='Buscar'
          handleSearch={this.handleSearch}
        >
          {this.props.error && (
            <Container
              disableGutters
              style={{
                marginBottom: '2rem'
              }}
            >
            </Container>
          )}
          <ResponsiveTable columns={this.getTableColumnsData()} data={this.state.users} />
          <Prompt
            visible={verify()}
            title='Página duplicada!'
            message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
            Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
            buttons={[
              {
                autoFocus: false
              },
            ]}
          />
        </MainContainer>
      </Container>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  list: () => api.get(dispatch, 'users', `Account`)
})

const mapStateToProps = state => ({
  loading: api.getApiLoading(state, `Account`),
  users: api.getApiLastSuccessfulResult(state, `Account`) || [],
  error: api.getApiError(state, `Account/`)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserList))
