import { AVAILABLE_ENTITIES, utils } from '../../database'
import { FAZENDA, formatName } from './../../utils/formHelper'
import ResponsiveTable, { CELL_TYPES } from '../../components/ResponsiveTable'
import React, { Component } from 'react'

import AddIcon from '@material-ui/icons/Add'
import { Container } from '@material-ui/core'
import MainContainer from '../../components/MainContainer'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit'

class BullList extends Component {
  constructor(props) {
    super(props)

    let sortedBulls = props.bulls
      .sort(function (a, b) {
        if (a.nome.toLowerCase() < b.nome.toLowerCase()) {
          return -1
        }
        if (a.nome.toLowerCase() > b.nome.toLowerCase()) {
          return 1
        }
        return 0
      })
      .map(bull => {
        const raca = _.find(this.props.racas, { id: bull.raca })
        const central = _.find(this.props.centrais, { id: bull.central })
        return {
          ...bull,
          raca: raca && raca.valor,
          central: central ? central.valor : bull.centralValue
        }
      })

    this.state = {
      allBulls: sortedBulls,
      selectedBulls: sortedBulls
    }

    this.handleSearch = this.handleSearch.bind(this)
  }

  handleSearch(e) {
    const val = e.target.value
    if (_.isEmpty(val)) {
      this.setState({ selectedBulls: this.state.allBulls })
    } else {
      const selectedBulls = this.state.allBulls.filter(
        x => x.nome.toLowerCase().indexOf(val.toLowerCase()) >= 0
      )
      this.setState({ selectedBulls })
    }
  }

  getTableColumnsData() {
    return [
      {
        name: 'Touro',
        type: CELL_TYPES.LINK,
        label: row => row.nome,
        onClick: row => {
          return () =>
            this.props.history.push(
              `/farms/${this.props.farm.id}/bulls/${row.id}`
            )
        }
      },
      {
        name: 'Raça',
        type: CELL_TYPES.TEXT,
        label: row => row.raca
      },
      {
        name: 'Central',
        type: CELL_TYPES.TEXT,
        label: row => row.central
      },
      {
        name: 'Partida',
        type: CELL_TYPES.TEXT,
        label: row =>
          row.partida ? row.partida.format('DD/MM/YYYY') : ''
      },
      {
        name: 'Observação',
        type: CELL_TYPES.TEXT,
        label: row => row.observacao
      },
      {
        type: CELL_TYPES.BUTTON_ARR,
        buttons: row =>
          _.compact([
            {
              type: CELL_TYPES.BUTTON,
              icon: <EditIcon />,
              label: 'Editar',
              onClick: () => {
                this.props.history.push(
                  `/farms/${this.props.farm.id}/bulls/${row.id}/update`
                )
              }
            },

          ])
      }
    ]
  }

  render() {
    return (
      <Container>
        <TopBar title={formatName(this.props.farm.nome, FAZENDA)} />
        <TitleBar
          title='Sêmen'
          buttons={[
            {
              onClick: () =>
                this.props.history.push(
                  `/farms/${this.props.farm.id}/bulls/create`
                ),
              icon: <AddIcon />,
              label: 'Sêmen'
            }
          ]}
          paths={[
            {
              route: `/farms/${this.props.farm.id}`,
              label: formatName(this.props.farm.nome, FAZENDA)
            },
            {
              label: `Sêmen`
            }
          ]}
        />

        <MainContainer hasSearch handleSearch={this.handleSearch}>
          <ResponsiveTable
            className="table-bulls"
            columns={this.getTableColumnsData()}
            data={this.state.selectedBulls}
          />
        </MainContainer>
      </Container>
    )
  }
}

const propsFromDatabase = async props => ({
  farm: await utils.getElement(AVAILABLE_ENTITIES.FARMS, props.match.params.id),
  bulls: await utils.getWithParam(
    // AVAILABLE_ENTITIES.BULLS,
    AVAILABLE_ENTITIES.BULLS_NEW,
    'farm_id',
    props.match.params.id
  ),
  racas: await utils.getDomainValuesBy('Raça do Touro'),
  centrais: await utils.getDomainValuesBy('Centrais')
})

export default utils.withPropsFromDatabase(
  propsFromDatabase,
  withRouter(BullList)
)
