/* eslint-disable array-callback-return */

import * as sync from '../../../src/syncWorker'

import {
  ANIMAL_DIED,
  ANIMAL_NOT_PRESENT_TO_MANAGEMENT,
  LOST_DEVICE,
} from '../../utils/constants'
import { AVAILABLE_ENTITIES, Repository, format, utils } from '../../database'
import { Container, Grid } from '@material-ui/core'
import {
  FAZENDA,
  LOTE,
  REQUIRED_FIELD_MESSAGES,
  RETIRO,
  formatName,
  missingRequiredFieldChecker,
  requiredFieldsEvaluator,
} from '../../utils/formHelper'
import React, { Component, Fragment } from 'react'

import Autocomplete from '../../components/material/Autocomplete'
import { CELL_TYPES } from './../../components/PaginatedTable'
import DoneIcon from '@material-ui/icons/Done'
import MainContainer from '../../components/MainContainer'
import Prompt from './../../components/Prompt'
import { Q } from '@nozbe/watermelondb'
import ResponsiveTable from './../../components/ResponsiveTable'
import TextField from '../../components/material/TextField'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import _ from 'lodash'
import { changeCowsToBatchNotes } from '../../utils/notesHelper'
import { getLoggedUser } from '../../redux/auth/actions'
import moment from 'moment'
import { sortList } from '../../utils/helper'
import track from 'react-tracking'
import { verify } from '../../utils/verifyDuplicatePages'
import { withRouter } from 'react-router-dom'

const obsOptions = [LOST_DEVICE, ANIMAL_NOT_PRESENT_TO_MANAGEMENT, ANIMAL_DIED]

@track(() => ({ page: 'DN', date: new Date() }))
class DN extends Component {
  constructor(props) {
    super(props)

    const codVacas = sortList(props.vacas, 'codVaca')

    const idToCowMap = _.reduce(
      props.vacas,
      (obj, vaca) => ({
        ...obj,
        [vaca.id]: vaca,
      }),
      {}
    )

    this.state = {
      ...idToCowMap,
      batchesIds: props.batches.map((batch) => batch.id),
      codVacas,
      selectedVacas: codVacas.filter(
        (vaca) => !(vaca.observacaoDG === 'Animal morreu')
      ),
      savedCows: [],
      showingFinalizeConfirmation: false,
      confirmedBackupDownload: true,
      showingNoBackupConfirmation: false,
      searchValue: '',
      showingChangeCowsToBatchConfirmation: false,
      searchInputCow: [],
      selectedCowToChangeToBatch: null,
    }

    this.handleSearch = this.handleSearch.bind(this)
    this.changeCowsToBatch = this.changeCowsToBatch.bind(this)
    this.saveCow = this.saveCow.bind(this)
    this.finalize = this.finalize.bind(this)

    this.renderHeader = this.renderHeader.bind(this)

    this.requiredFieldsEvaluator = requiredFieldsEvaluator.bind(this)
    this.missingRequiredFieldChecker = missingRequiredFieldChecker.bind(this)

    this.renderTitle = this.renderTitle.bind(this)

    this.saveFilledCows()
  }

  saveFilledCows() {
    this.state.selectedVacas.map((v) =>
      v.observacaoDN != null ? this.state.savedCows.push(v.id) : null
    )
  }

  onEnterPress = (id, e) => {
    if (this.props.user.roles[0].name !== 'Cliente (somente visualização)') {
      if (!(this.state.savedCows.indexOf(id) >= 0)) {
        if (e.key === 'Enter' && e.shiftKey === false) {
          e.preventDefault()
          this.saveCow(id)
        }
      }
    }
  }

  @track({ action: 'Finalizou o DN' })
  async finalize(downloadBackup) {
    const repository = new Repository(AVAILABLE_ENTITIES.BATCHES)
    let success = true
    for (const cow of this.props.vacas) {
      if (success && this.state.savedCows.indexOf(cow.id) === -1) {
        success = await this.saveCow(cow.id)
      }
    }
    if (success) {
      for (const batch of this.props.batches) {
        const updateRequest = await repository.update({
          id: batch.id,
          dias: this.props.currentDay.id,
        })
        if (!updateRequest.success) {
          success = false
          console.log(
            `There was an error finalizing DN for batch ${batch.id}: ${updateRequest.exception}`
          )
        }
      }
    }
    if (success) {
      console.log('DN Successfully ended')
      const uniqueCorrals = _.uniq(
        this.props.corrals.map((corral) => corral.id)
      )
      if (this.props.farm.retiro && uniqueCorrals.length === 1) {
        this.props.history.push(
          `/farms/${this.props.farm.id}/corrals/${uniqueCorrals[0]}`
        )
      } else {
        this.props.history.push(`/farms/${this.props.farm.id}`)
      }
    }

    sync.downloadProofOfManagement({
      values: ['lotes', 'd0s', 'dns'],
      text: 'Comprovante de manejo DN - ' +
        formatName(this.props.batches.map((item) => item.nomeLote), LOTE) +
        ' - ' +
        formatName(this.props.farm.nome, FAZENDA) +
        ' - ' +
        moment.utc().valueOf() +
        '.progerar',
      batchIds: this.props.match.params.id.split(';')
    })
  }

  @track((props, state, cowId) => ({ action: 'Salvou a DN de uma matriz', value: cowId[0] }))
  async saveCow(id) {
    const cow = this.state[id]
    const propsCow = _.find(this.props.vacas, { id: cow.id })
    let success = true
    if (cow) {
      this.setState({
        savedCows: [...this.state.savedCows, cow.id],
      })

      const obs = cow.observacaoDN
      const codVaca = cow.codVaca

      const payloadD0 = {
        id: cow.id,
        observacaoDG: cow.observacaoDN === 'Animal morreu' ? '[N/A]' : null,
      }

      let payload = {
        vacaId: cow.id,
        loteId: cow.loteId,
        dia: this.props.currentDay.id,
      }

      payload.observacao = _.isEmpty(obs)
        ? '[N/A]'
        : obsOptions.indexOf(obs) >= 0
          ? `[${obs}] ${obs}`
          : obs

      if (_.isEmpty(codVaca)) {
        this.setState({
          [id]: {
            ...cow,
            codVaca_error: REQUIRED_FIELD_MESSAGES.DEFAULT,
          },
          savedCows: this.state.savedCows.filter((x) => x !== id),
        })
      } else {
        const repository = new Repository(AVAILABLE_ENTITIES.DNS)
        const repositoryD0 = new Repository(AVAILABLE_ENTITIES.D0S)

        repositoryD0.update(payloadD0)
        const existingRequest = await repository.getByParam('vaca_id', cow.id)
        if (existingRequest.success) {
          const existingEntry = _.find(existingRequest.response, {
            dia: this.props.currentDay.id,
          })
          if (!_.isEmpty(existingEntry)) {
            const updateRequest = await repository.update({
              ...payload,
              id: existingEntry.id,
            })
            if (!updateRequest.success) {
              console.log(
                `There was a problem trying to update entry for cow ${cow.id}: ${updateRequest.exception}`
              )
              success = false
            }
          } else {
            const createRequest = await repository.post(payload)
            if (!createRequest.success) {
              console.log(
                `There was a problem trying to create entry for cow ${cow.id}: ${createRequest.exception}`
              )
              success = false
            }
          }
        } else {
          console.log(
            `There was a problem trying to search for existing entry for cow: ${cow.id}: ${existingRequest.exception}`
          )
          success = false
        }

        if (success && propsCow && propsCow.codVaca !== codVaca) {
          const d0Repo = new Repository(AVAILABLE_ENTITIES.D0S)
          const updateReq = await d0Repo.update({
            id: cow.id,
            codVaca,
          })
          if (updateReq.success) {
            console.log(
              `codVaca successfully changed from ${propsCow.codVaca} to ${codVaca} for cow: ${cow.id}`
            )
            let codVacas = this.state.codVacas.filter(
              (entry) => entry.id !== cow.id
            )
            this.setState({
              codVacas: [...codVacas, { id: cow.id, codVaca }],
            })
          } else {
            console.log(
              `There was a problem updating codVaca for cow: ${cow.id}: ${updateReq.exception}`
            )
            success = false
          }
        }
        if (!success) {
          this.setState({
            [cow.id]: this.state,
            savedCows: this.state.savedCows.filter((x) => x !== cow.id),
          })
        }
      }
    }

    return success
  }

  async handleSearch(e) {
    const val = e.target.value
    const selectedCodVacas = _.isEmpty(val)
      ? this.state.codVacas
      : this.state.codVacas.filter(
        (entry) =>
          entry.codVaca?.toLowerCase().indexOf(val.toLowerCase()) >= 0
      )
    const filtedCodLeitorBastao = _.isEmpty(val)
      ? this.state.codVacas
      : this.state.codVacas.filter(
        (entry) =>
          entry.codLeitorBastao?.toLowerCase().indexOf(val.toLowerCase()) >= 0
      )
    const filtedAllData = selectedCodVacas.concat(filtedCodLeitorBastao)
    const selectedVacas = [...new Set(filtedAllData)]
    let searchError = null
    let searchInputCow = []

    if (selectedVacas.length === 0 && !_.isEmpty(val)) {
      const outOfBatch = await utils.farmWideSearch(
        val,
        this.state.batchesIds,
        this.props.farm.id
      )

      if (outOfBatch.msg) {
        searchError = outOfBatch.msg
        searchInputCow = outOfBatch.data
      }
    }

    this.setState({
      selectedVacas,
      searchError,
      searchInputCow,
      searchValue: selectedVacas.length > 0 ? e?.target?.value : '',
    })
  }

  async changeCowsToBatch(cow) {
    try {
      let updatedMatriz = {}

      updatedMatriz = {
        ...cow,
        loteId: this.props.loteId,
        observacaoD0: 'Animal mudou de lote'
      }

      const repository = new Repository(AVAILABLE_ENTITIES.D0S)
      const createReq = await repository.update(updatedMatriz)

      if (createReq.success) {
        console.log('D0 updated successfully')

        this.setState({
          codVacas: [...this.state.codVacas, updatedMatriz],
        })

        setTimeout(() => {
          window.location.reload()
        }, 500)
      } else {
        console.log(
          `There was an error trying to update D0: ${createReq.exception}`
        )
      }
    } catch (exception) {
      console.log(`There was an error trying to update D0: ${exception}`)
    }
  }

  renderHeader() {
    return (
      <div className='grid grid-dn p-margin-bottom-1' style={{ padding: 0 }}>
        <div className='grid-item p-12 t-6 header-info-left'>
          {this.renderManejos()}
        </div>

        <div className='grid-item p-12 t-6 header-info-right'>
          <h2 className='title-bigger'>
            TOTAL DE MATRIZES: {this.state.savedCows.length}/
            {this.props.vacas ? this.props.vacas.length : 0}
          </h2>
        </div>

        <div className='grid-item p-12 p-padding-vertical-1'>
          <hr />
        </div>
      </div>
    )
  }

  renderManejos() {
    return (
      <ul>
        {this.props.selectedProtocols.map((protocol, index) => {
          const dosage = protocol.dosage
            ? protocol.dosage.valor
            : protocol.dosageValue
          return (
            <li key={`protocol_${index}`}>
              <strong>
                {protocol.management && protocol.management.valor}
                {(protocol.hormone ||
                  protocol.dosage ||
                  protocol.dosageValue) &&
                  ':'}{' '}
              </strong>
              {protocol.hormone ? protocol.hormone.valor : ''}{' '}
              {dosage ? dosage + ' mL' : ''}
            </li>
          )
        })}
      </ul>
    )
  }

  renderTitle() {
    return (
      <Fragment>
        Protocolo: {this.props.protocol.name}
        <br />
        Manejo: {this.props.currentDay ? this.props.currentDay.valor : 'DN'}
      </Fragment>
    )
  }

  renderField(row, index) {
    return (
      <Fragment>
        {this.props.isResync ? (
          <Grid container>
            {this.props.isMerging && (
              <Grid item xs={12}>
                {row.lote}
              </Grid>
            )}
            <Grid item xs={12}>
              {row.codVaca}
            </Grid>
          </Grid>
        ) : (
          <TextField
            id={`codVaca_${index}`}
            label='ID/Matriz'
            onChange={(e) => {
              const value = e.target.value

              this.setState({
                [row.id]: {
                  ...row,
                  observacaoDN: this.state[row.id].observacaoDN,
                  codVaca: value,
                  codVaca_error: _.isEmpty(value)
                    ? REQUIRED_FIELD_MESSAGES.DEFAULT
                    : null,
                },
                savedCows: this.state.savedCows.filter((cow) => cow !== row.id),
              })
            }}
            onKeyDown={(e) => this.onEnterPress(row.id, e)}
            value={this.state[row.id].codVaca || null}
            error={!_.isEmpty(this.state[row.id].codVaca_error)}
            helperText={this.state[row.id].codVaca_error}
            withGrid={12}
            tabIndex={this.state.searchValue !== '' ? -1 : null}
          />
        )}
        {row.previousObs &&
          row.previousObs.map((obs, index) => {
            const style = index === 0 ? { marginTop: '1em' } : null
            return (
              <Grid item key={`${row.id}_obs_${index}`} xs={12} style={style}>
                <b>{obs.manejo}: </b>
                {obs.obs}
              </Grid>
            )
          })}
      </Fragment>
    )
  }

  renderObservation(row, index) {
    return (
      <Grid container alignContent='flex-start'>
        <Grid item xs={12}>
          <Autocomplete
            id={`obs_${index}`}
            options={obsOptions}
            onChange={(e, value) => {
              this.setState({
                [row.id]: {
                  ...row,
                  observacaoDN: value,
                  codVaca: this.state[row.id].codVaca,
                  codVaca_error: _.isEmpty(this.state[row.id].codVaca)
                    ? REQUIRED_FIELD_MESSAGES.DEFAULT
                    : null,
                },
                savedCows: this.state.savedCows.filter((cow) => cow !== row.id),
              })
            }}
            onKeyDown={
              (this.state[row.id].observacaoDN ||
                this.state[row.id].observacaoDN_value) &&
                this.state[row.id].observacaoDN.length > 3
                ? (e) => this.onEnterPress(row.id, e)
                : null
            }
            label='Observações'
            value={this.state[row.id].observacaoDN || null}
            openField
          />
        </Grid>
      </Grid>
    )
  }

  getTableColumnData() {
    return [
      {
        name: 'Matriz (ID)',
        type: CELL_TYPES.FIELD,
        field: (row, index) => this.renderField(row, index),
        grid: 4,
      },
      {
        name: 'Observações',
        type: CELL_TYPES.FIELD,
        field: (row, index) => this.renderObservation(row, index),
        grid: 6,
      },
      {
        type: CELL_TYPES.BUTTON,
        label: (row, index) => 'OK',
        onClick: (row, index) => {
          return () =>
            this.state.savedCows.indexOf(row.id) >= 0
              ? console.log(`There's nothing to save`)
              : this.saveCow(row.id)
        },
        disabled: (row, index) => this.state.savedCows.indexOf(row.id) >= 0 || this.props.user.roles[0].name === 'Cliente (somente visualização)',
        grid: 2,
      },
    ]
  }

  getButtons() {
    return [
      {
        onClick: () => this.setState({ showingFinalizeConfirmation: true }),
        icon: <DoneIcon />,
        label: 'Finalizar',
        disabled: this.props.user.roles[0].name === 'Cliente (somente visualização)'
      },
    ]
  }

  getPaths() {
    const uniqueCorrals = _.uniq(this.props.corrals.map((corral) => corral.id))
    const extraPath =
      this.props.farm.retiro && uniqueCorrals.length === 1
        ? {
          route: `/farms/${this.props.farm.id}/corrals/${uniqueCorrals[0]}`,
          label: formatName(
            _.find(this.props.corrals, { id: uniqueCorrals[0] }).nome,
            RETIRO
          ),
        }
        : null

    const batchPath =
      this.props.batches.length === 1
        ? {
          route: `/farms/${this.props.farm.id}/corrals/${this.props.batches[0].retiroId
            }/batches/${this.props.batches[0].originalBatchId
              ? this.props.batches[0].originalBatchId
              : this.props.batches[0].id
            }`,
          label: formatName(this.props.batches[0].nomeLote, LOTE),
        }
        : {
          label: 'Vários Lotes',
        }

    return _.compact([
      {
        route: `/farms/${this.props.farm.id}`,
        label: formatName(this.props.farm.nome, FAZENDA),
      },
      extraPath,
      batchPath,
      {
        label: this.props.currentDay ? this.props.currentDay.valor : 'DN',
      },
    ])
  }

  render() {
    return (
      <Container>
        <TopBar title={formatName(this.props.farm.nome, FAZENDA)} />
        <TitleBar
          title={this.renderTitle()}
          buttons={this.getButtons()}
          paths={this.getPaths()}
        />
        <MainContainer
          hasSearch
          hasValue={this.state.searchValue !== '' ? true : false}
          handleSearch={this.handleSearch}
          preSearchChildren={this.renderHeader()}
          useVoiceRecognition
          includeDivider
          alert={this.state.searchError}
          cowsValues={this.state.searchInputCow}
          hasChangeCowsToBatch={
            this.state.searchInputCow &&
              !this.props.isResync &&
              this?.state?.searchInputCow[0]?.iatf === 0
              ? true
              : false
          }
          changeCowsToBatch={(item) =>
            this.setState({
              showingChangeCowsToBatchConfirmation: true,
              selectedCowToChangeToBatch: item,
            })
          }
        >
          <Container disableGutters style={{ marginTop: '2em' }}>
            <ResponsiveTable
              columns={this.getTableColumnData()}
              data={this.state.selectedVacas}
              className='table-dn'
            />

            <Prompt
              visible={verify()}
              title='Página duplicada!'
              message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
                Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
              buttons={[
                {
                  autoFocus: false,
                },
              ]}
            />

            <Prompt
              visible={this.state.showingChangeCowsToBatchConfirmation}
              title={changeCowsToBatchNotes.title}
              message={changeCowsToBatchNotes.text}
              buttons={[
                {
                  label: 'Cancelar',
                  onClick: () =>
                    this.setState({
                      showingChangeCowsToBatchConfirmation: false,
                    }),
                },
                {
                  label: 'Mover',
                  onClick: () =>
                    this.changeCowsToBatch(
                      this.state.selectedCowToChangeToBatch
                    ),
                },
              ]}
            />
            <Prompt
              visible={this.state.showingFinalizeConfirmation}
              title='Deseja Finalizar?'
              message='Uma vez finalizado os dados não salvos serão perdidos.'
              buttons={[
                {
                  label: 'Não',
                  onClick: () =>
                    this.setState({
                      showingFinalizeConfirmation: false,
                      confirmedBackupDownload: true,
                    }),
                },
                {
                  label: 'Sim',
                  onClick: this.state.confirmedBackupDownload
                    ? () => this.finalize(true)
                    : () =>
                      this.setState({
                        showingFinalizeConfirmation: false,
                        showingNoBackupConfirmation: true,
                      }),
                },
              ]}
            />
            <Prompt
              visible={this.state.showingNoBackupConfirmation}
              title='Atenção!'
              message='O comprovante de manejo pode ser necessário durante um eventual cenário de perda de dados. Não baixar o comprovante poderá dificultar a recuperação das informações desse manejo. Deseja continuar mesmo assim?'
              buttons={[
                {
                  label: 'Cancelar',
                  onClick: () =>
                    this.setState({
                      showingNoBackupConfirmation: false,
                      showingFinalizeConfirmation: true,
                    }),
                },
                {
                  label: 'Sim',
                  onClick: () => this.finalize(false),
                },
              ]}
            />
          </Container>
        </MainContainer>
      </Container>
    )
  }
}

function omit_deadCows(vaca, vacasMortas) {
  let morta = false
  vacasMortas.map((vacaMorta) => {
    if (vacaMorta === vaca.id) {
      morta = true
    }
  })

  if (vaca.omit_from_dg_final || morta) {
    return false
  }
  return true
}

const propsFromDatabase = async (props) => {
  const batchIds = props.match.params.id.split(';')

  const batches = await utils.getWithParam(
    AVAILABLE_ENTITIES.BATCHES,
    'id',
    Q.oneOf(batchIds)
  )

  const corrals = await utils.getWithParam(
    AVAILABLE_ENTITIES.CORRALS,
    'id',
    Q.oneOf(_.uniq(batches.map((batch) => batch.retiroId)))
  )

  const isMerging = batchIds.length > 1

  const protocol = await utils.getElement(
    AVAILABLE_ENTITIES.PROTOCOLS,
    batches[0].protocoloId
  )
  const days = await utils.getDomainValuesBy('Dias')
  const protocolDays = protocol
    ? _.sortBy(
      _.uniq(
        protocol.managementProtocols.map((managementProtocol) =>
          _.find(days, { id: managementProtocol.day })
        )
      ),
      [
        (dia) =>
          dia.valor.startsWith('D') ? parseInt(dia.valor.substring(1)) : 100,
      ]
    )
    : []
  const currentDay =
    protocolDays[_.findIndex(protocolDays, { id: batches[0].dias }) + 1]

  const protocolsForDay =
    protocol && currentDay
      ? protocol.managementProtocols.filter(
        (managementProtocol) => managementProtocol.day === currentDay.id
      )
      : []

  const selectedProtocols = await Promise.all(
    protocolsForDay.map(async (selectedProtocol) => {
      const management = await utils.getElement(
        AVAILABLE_ENTITIES.DOMAIN_VALUES,
        selectedProtocol.managementId
      )
      const hormone = await utils.getElement(
        AVAILABLE_ENTITIES.DOMAIN_VALUES,
        selectedProtocol.hormoneTypeId
      )

      const dosage = await utils.getElement(
        AVAILABLE_ENTITIES.DOMAIN_VALUES,
        selectedProtocol.hormoneDosageId
      )

      return {
        management,
        hormone,
        dosage,
        dosageValue: selectedProtocol.hormoneDosageValue,
      }
    })
  )
  let deadCows = []

  let vacasAtuais = _.filter(
    await utils.getWithParam(
      AVAILABLE_ENTITIES.D0S,
      'batch_id',
      Q.oneOf(batchIds)
    )
  )

  vacasAtuais.map((vaca) => {
    if (
      (vaca.observacaoD0 === 'Animal morreu' || vaca.observacaoD0 === 'Matriz Descarte') ||
      vaca.observacaoDG === 'Animal morreu'
    ) {
      deadCows.push(vaca.id)
    }
  })

  let vacas = _.filter(
    await utils.getWithParam(
      AVAILABLE_ENTITIES.D0S,
      'batch_id',
      Q.oneOf(batchIds)
    ),
    (vaca) => omit_deadCows(vaca, deadCows)
  )

  for (const vaca of vacas) {
    vaca.lote = _.find(batches, { id: vaca.loteId }).nomeLote

    let previousObs = []

    if (!_.isEmpty(vaca.observacaoD0) && vaca.observacaoD0 !== '[N/A]') {
      previousObs.push({
        manejo: 'D0',
        obs: format.sanitizeObs(vaca.observacaoD0),
      })
    }

    const previousDns = _.filter(
      await utils.getWithParam(AVAILABLE_ENTITIES.DNS, 'vacaId', vaca.id),
      (dn) => dn.dia === currentDay.id
    )

    if (previousDns.length > 0) {
      vaca.observacaoDN = format.sanitizeObs(
        previousDns[previousDns.length - 1].observacao
      )
    }

    const dns = _.filter(
      await utils.getWithParam(AVAILABLE_ENTITIES.DNS, 'vacaId', vaca.id),
      (dn) => dn.dia !== currentDay.id
    )
    if (dns.length > 0) {
      const sortedObs = _.sortBy(
        _.compact(
          dns.map(
            (dn) =>
              !_.isEmpty(dn.observacao) &&
              dn.observacao !== '[N/A]' && {
                manejo: _.find(days, { id: dn.dia }).valor,
                obs: format.sanitizeObs(dn.observacao),
              }
          )
        ),
        [
          (obs) =>
            obs.manejo.startsWith('D')
              ? parseInt(obs.manejo.substring(1))
              : 100,
        ]
      )
      previousObs = previousObs.concat(sortedObs)
    }

    if (previousObs.length > 0) {
      vaca.previousObs = previousObs
    }
  }
  const user = await utils.getElement(
    AVAILABLE_ENTITIES.USERS,
    (
      await getLoggedUser()
    ).userId
  )

  return {
    batches,
    corrals,
    isMerging,
    loteId: batches[0].id,
    isResync: isMerging || (batches.length === 1 && batches[0].isResync),
    farm: await utils.getElement(
      AVAILABLE_ENTITIES.FARMS,
      corrals[0].fazendaId
    ),
    vacas,
    protocol,
    currentDay,
    selectedProtocols,
    user
  }
}

export default utils.withPropsFromDatabase(propsFromDatabase, withRouter(DN))
