import { AVAILABLE_ENTITIES, utils } from '../../database'
import Autocomplete, {
  autocompleteDefaultOnChange,
  autocompleteDefaultOnChangeSavingID,
  autocompleteOptionLabelUsingCustomField,
  autocompleteOptionLabelUsingCustomFieldSavingID,
} from '../../components/material/Autocomplete'
import Button, { COLORS } from '../../components/material/Button'
import React, { Component } from 'react'
import ResponsiveTable, { CELL_TYPES } from '../../components/ResponsiveTable'
import {
  FAZENDA,
  RETIRO,
  missingRequiredFieldChecker,
  requiredFieldsEvaluator,
} from '../../utils/formHelper'

import AddIcon from '@material-ui/icons/Add'
import Container from '@material-ui/core/Container'
import CustomSwitch from '../../components/CustomSwitch'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MainContainer from '../../components/MainContainer'
import Prompt from '../../components/Prompt'
import RemoveIcon from '@material-ui/icons/Remove'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import _ from 'lodash'
import { formatName } from '../../utils/formHelper'
import { getBatchesByFarm } from '../../utils/batches'
import { getLoggedUser } from '../../redux/auth/actions'
import track from 'react-tracking'
import { verify } from './../../utils/verifyDuplicatePages'
import { withRouter } from 'react-router-dom'

@track(() => ({ page: 'Mesclar Lotes', date: new Date() }))
class BatchMerge extends Component {
  constructor(props) {
    super(props)
    this.state = {
      protocols: _.keys(props.data).map((key) => ({
        id: key,
        name: props.data[key].name,
      })),
      days: [],
      batches: [],
      dgFinalBatches: this.props.infoBatches.filter(
        (b) => this.currentDayIsDGFinal(b) && b
      ),
      dgsBatches: this.props.infoBatches.filter(
        (b) =>
          (this.currentDayIsDG(b) && b) || (this.currentDayIsDGFinal(b) && b)
      ),
      dgsFinalBatches: this.props.infoBatches.filter(
        (b) =>
          (this.currentDayIsDG(b) && b) || (this.currentDayIsDGFinal(b) && b)
      ),
      selectedBatches: [],
      selectedDGFinalBatches: [],
      showingPrompt: false,
      showingDGFinalPrompt: false,
      checked: false,
      manejo: false,
      isDg: false,
    }

    this.merge = this.merge.bind(this)
    this.mergeDGFinal = this.mergeDGFinal.bind(this)
    this.addBatch = this.addBatch.bind(this)
    this.addDGFinalBatch = this.addDGFinalBatch.bind(this)
    this.removeProtocol = this.removeProtocol.bind(this)
    this.onCancel = this.onCancel.bind(this)

    this.autocompleteDefaultOnChange = autocompleteDefaultOnChange.bind(this)
    this.autocompleteDefaultOnChangeSavingID =
      autocompleteDefaultOnChangeSavingID.bind(this)
    this.autocompleteOptionLabelUsingCustomFieldSavingID =
      autocompleteOptionLabelUsingCustomFieldSavingID.bind(this)
    this.autocompleteOptionLabelUsingCustomField =
      autocompleteOptionLabelUsingCustomField.bind(this)

    this.missingRequiredFieldChecker = missingRequiredFieldChecker.bind(this)
    this.requiredFieldsEvaluator = requiredFieldsEvaluator.bind(this)
  }

  currentDayIsD0(row) {
    return (
      !row.pastDGFinal &&
      (!row.dias || row.dias === '') &&
      !(row.isResync && !row.isPrecoce)
    )
  }

  currentDayIsD0IATF(row) {
    return this.currentDayIsD0(row) && !row.isResync
  }

  currentDayIsD0Ressinc(row) {
    return (
      !row.pastDGFinal &&
      (!row.dias || row.dias === '') &&
      row.isResync &&
      row.isPrecoce
    )
  }

  currentDayIsDN(row) {
    return (
      !row.pastDGFinal &&
      !(!row.dias || row.dias === '') &&
      !(row.isLastDay || row.hasIatf) &&
      row.nextDay &&
      !(row.isResync && row.isPrecoce && !row.parentPastDG)
    )
  }

  currentDayIsIatf(row) {
    return !row.pastDGFinal && (row.isLastDay || row.hasIatf)
  }

  currentDayIsRessinc(row) {
    return !row.pastDGFinal && row.dias && !row.nextDay && !row.pastDG
  }

  currentDayIsDG(row) {
    return (
      !row.pastDGFinal &&
      !(!row.dias || row.dias === '') &&
      !row.nextDay &&
      !row.pastDG
    )
  }

  currentDayIsDGFinal(row) {
    return (
      !row.pastDGFinal &&
      !(!row.dias || row.dias === '') &&
      !row.nextDay &&
      row.pastDG
    )
  }

  currentDayIsPastDGFinal(row) {
    return (
      row.pastDGFinal
    )
  }

  currentDayIsDGDN(row) {
    return (
      !row.pastDGFinal &&
      !(!row.dias || row.dias === '') &&
      row.nextDay &&
      row.isResync &&
      row.isPrecoce &&
      !row.parentPastDG
    )
  }

  currentDayIsDGD0(row) {
    return (
      !row.pastDGFinal &&
      (!row.dias || row.dias === '') &&
      row.isResync &&
      !row.isPrecoce
    )
  }

  managementsOver(row) {
    return row.pastDGFinal
  }

  @track((props, state, event) => ({
    action: 'Mudou o Switch',
    value: event[1],
  }))
  handleSwitchChange = (event) => {
    this.setState({
      checked: event.target.checked,
      day: [],
    })
  }

  @track((props, state) => ({
    action: 'Mesclou Lotes',
    value: state.selectedBatches.map((batch) => batch.id),
  }))
  merge() {
    const target = `/${this.state.selectedBatches[0].target
      }/${this.state.selectedBatches.map((batch) => batch.id).join(';')}`

    this.props.history.push(target, {
      manejo: this.state.day,
      farm: {
        id: this.props.farm.id,
        label: formatName(this.props.farm.nome, FAZENDA)
      },
      retiro: {
        id: this.props.corral.id,
        label: formatName(this.props.corral.nome, RETIRO)
      }
    })
  }

  @track((props, state) => ({
    action: 'Mesclou Lotes DG ou DG Final',
    value: state.selectedDGFinalBatches.map((batch) => batch.id),
  }))
  mergeDGFinal() {
    if (this.state.isDg) {
      const target = `/DG/${this.state.selectedDGFinalBatches
        .map((batch) => batch.id)
        .join(';')}`
      this.props.history.push(target, {
        manejo: this.state.day,
        farm: {
          id: this.props.farm.id,
          label: formatName(this.props.farm.nome, FAZENDA)
        },
        retiro: {
          id: this.props.corral.id,
          label: formatName(this.props.corral.nome, RETIRO)
        }
      })
    } else {
      const target = `/DGFinal/${this.state.selectedDGFinalBatches
        .map((batch) => batch.id)
        .join(';')}`
      this.props.history.push(target)
    }
  }

  onCancel() {
    if (this.props.farm.retiro) {
      this.props.history.push(
        `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}`
      )
    } else {
      this.props.history.push(`/farms/${this.props.farm.id}`)
    }
  }

  onEnterPress = (e) => {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault()
      this.addBatch()
    }
  }

  addBatch() {
    let selectedBatches = this.state.selectedBatches || []
    if (!_.isEmpty(this.state.batch)) {
      selectedBatches.push(this.state.batch)
    }
    this.setState({
      selectedBatches,
      batch: null,
    })
  }

  addDGFinalBatch() {
    let selectedDGFinalBatches = this.state.selectedDGFinalBatches || []
    if (!_.isEmpty(this.state.dgFinalBatch)) {
      selectedDGFinalBatches.push(this.state.dgFinalBatch)
    }
    this.setState({
      selectedDGFinalBatches,
      dgFinalBatch: null,
    })
  }

  removeProtocol(index) {
    let selectedBatches = this.state.selectedBatches
    selectedBatches.splice(index, 1)
    this.setState({
      selectedBatches,
    })
  }

  removeDGFinalColumn(index) {
    let selectedDGFinalBatches = _.sortBy(this.state.selectedDGFinalBatches, [
      (batch) => batch.nomeLote.toLowerCase(),
    ])
    selectedDGFinalBatches.splice(index, 1)
    this.setState({
      selectedDGFinalBatches,
    })
  }

  getTableColumnsData() {
    return [
      {
        name: 'Protocolo',
        type: CELL_TYPES.TEXT,
        label: (row) => row.protocol.name,
        grid: 3,
      },
      {
        name: 'Manejo',
        type: CELL_TYPES.TEXT,
        label: (row) => `${row.currentDay} (IATF ${row.iatf})`,
        grid: 3,
      },
      {
        name: 'Lote',
        type: CELL_TYPES.TEXT,
        label: (row) => row.name,
        grid: 5,
      },
      {
        name: 'Retiro',
        type: CELL_TYPES.TEXT,
        label: (row) => {
          const retiro = this.props.corrals.find(
            (item) => item.id === row.retiroId
          )

          return retiro.nome === 'DEFAULT' ? '-' : retiro.nome
        },
        grid: 5,
      },
      {
        type: CELL_TYPES.HOLLOW_BUTTON,
        icon: (row) => <RemoveIcon />,
        onClick: (row, index) => {
          return () => this.removeProtocol(index)
        },
        grid: 1,
      },
    ]
  }

  getDGFinalTableColumnsData() {
    return [
      {
        name: 'Lote',
        type: CELL_TYPES.TEXT,
        label: (row) => row.nomeLote,
        grid: 5,
      },
      {
        name: 'Retiro',
        type: CELL_TYPES.TEXT,
        label: (row) => {
          const retiro = this.props.corrals.find(
            (item) => item.id === row.retiroId
          )

          return retiro.nome === 'DEFAULT' ? '-' : retiro.nome
        },
        grid: 5,
      },
      {
        type: CELL_TYPES.HOLLOW_BUTTON,
        icon: (row) => <RemoveIcon />,
        onClick: (row, index) => {
          return () => this.removeDGFinalColumn(index)
        },
        grid: 1,
      },
    ]
  }

  getTableColumns() {
    return _.sortBy(this.state.selectedBatches, [
      (batch) => batch.name.toLowerCase(),
    ])
  }

  getDGFinalTableColumns() {
    return _.sortBy(this.state.selectedDGFinalBatches, [
      (batch) => batch.nomeLote.toLowerCase(),
    ])
  }

  getPaths() {
    const corralPath = this.props.farm.retiro && {
      route: `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}`,
      label: formatName(this.props.corral.nome, 'retiro'),
    }

    return _.compact([
      {
        route: `/farms/${this.props.farm.id}`,
        label: this.props.farm.nome,
      },
      corralPath,
      {
        label: 'Mesclar Lotes',
      },
    ])
  }

  getButtons() {
    return [
      {
        onClick: () =>
          this.state.checked
            ? this.setState({ showingDGFinalPrompt: true })
            : this.setState({ showingPrompt: true }),
        label: 'Mesclar Lotes',
        disabled: this.props.user.roles[0] === 'Cliente (somente visualização)'
      },
    ]
  }

  handleMergeCorrals = (selectedBatches, valueToSwitch = false) => {
    let title = ''
    let message = ''
    let buttons = []

    if (selectedBatches.length > 1) {
      if (valueToSwitch) {
        message = `Você irá realizar o ${this.state.day === 'DG' ? 'DG' : 'DG Final'
          } para os lotes: ${selectedBatches
            .map((batch) => batch.nomeLote)
            .join(', ')}`
      } else {
        message = `Você irá realizar ${selectedBatches[0].currentDay
          } para os lotes: ${selectedBatches
            .map((batch) => batch.name)
            .join(', ')}`
      }
      title = 'Deseja Mesclar os Lotes?'
      buttons = [
        {
          label: 'Não',
          onClick: () => valueToSwitch
            ? this.setState({ showingDGFinalPrompt: false })
            : this.setState({ showingPrompt: false })
        },
        {
          label: 'Sim',
          onClick: valueToSwitch
            ? this.mergeDGFinal
            : this.merge,
        },
      ]
    } else {
      message = 'Você precisa selecionar pelo menos dois lotes para continuar'
      title = 'Mesclar Lotes'
      buttons = [
        {
          label: 'OK',
          onClick: () => valueToSwitch
            ? this.setState({ showingDGFinalPrompt: false })
            : this.setState({ showingPrompt: false }),
        },
      ]
    }

    return {
      message,
      title,
      buttons,
    }
  }

  render() {
    return (
      <Container>
        <TopBar title={this.props.farm.nome} />
        <TitleBar
          title='Mesclar Lotes'
          paths={this.getPaths()}
          buttons={this.getButtons()}
        />
        <MainContainer>
          <div className='grid'>
            <div className='grid-item t-8' />
            <div className='grid-item p-12 t-4'>
              <CustomSwitch
                onHandleChange={this.handleSwitchChange}
                checked={this.state.checked}
                text='Mesclar Lotes em DG e DG Final'
              ></CustomSwitch>
            </div>
          </div>
          <Divider
            style={{
              marginTop: '1.5em',
              width: '100%',
              height: '2px',
              marginBottom: '32px',
            }}
          />

          {this.state.checked ? (
            <div className='grid grid-merge'>
              <div className='grid-item p-12 t-3'>
                <Autocomplete
                  id='day'
                  options={['DG', 'DG Final']} //
                  value={this.state.day || ''}
                  onChange={(e, value) => {
                    this.autocompleteDefaultOnChange('day')(e, value)
                    this.setState({
                      dgFinalBatch: null,
                      batches: _.isEmpty(value)
                        ? []
                        : _.sortBy(this.props.data, [
                          (batch) => batch.name.toLowerCase(),
                        ]),
                      manejo: !_.isEmpty(value) && true,
                      dgsBatches:
                        value === 'DG'
                          ? this.props.infoBatches.filter(
                            (b) => this.currentDayIsDG(b) && b
                          )
                          : this.state.selectedDGFinalBatches
                            ? this.props.infoBatches.filter(
                              (b) =>
                                (this.currentDayIsDG(b) && b) ||
                                ((this.currentDayIsDGFinal(b) || this.currentDayIsPastDGFinal(b)) && b)
                            )
                            : this.props.infoBatches.filter(
                              (b) =>
                                (this.currentDayIsDG(b) && b) ||
                                (this.currentDayIsDGFinal(b) && b)
                            ),
                      selectedDGFinalBatches: [],
                      isDg: value === 'DG' ? true : false,
                    })
                  }}
                  onKeyDown={this.state.day && this.onEnterPress}
                  useDefaultOptionSelected
                  label='Manejo'
                  error={!_.isEmpty(this.state.management_error)}
                  helperText={this.state.management_error}
                />
              </div>

              <div className='grid-item p-10 t-5'>
                <Autocomplete
                  id='dgs'
                  options={
                    this.state.selectedDGFinalBatches
                      ? this.state.dgsBatches.filter(
                        (d) =>
                          this.state.selectedDGFinalBatches
                            .map((d2) => d2.id)
                            .indexOf(d.id) < 0 && d
                      )
                      : this.state.dgsBatches
                  }
                  value={this.state.dgFinalBatch || ''}
                  onChange={this.autocompleteDefaultOnChange('dgFinalBatch')}
                  onKeyDown={this.state.dgFinalBatch && this.onEnterPress}
                  getOptionLabel={this.autocompleteOptionLabelUsingCustomField(
                    'nomeLote'
                  )}
                  useDefaultOptionSelected
                  label='Lotes Para Mescla'
                  disabled={this.state.manejo === false}
                  error={!_.isEmpty(this.state.dgFinalBatch_error)}
                  helperText={this.state.dgFinalBatch_error}
                />
              </div>

              <div className='grid-item p-2 t-1 p-display-flex p-align-items-center'>
                <IconButton
                  onClick={this.addDGFinalBatch}
                  disabled={!this.state.dgFinalBatch || !this.state.day}
                  className='plus-icon'
                >
                  <AddIcon />
                </IconButton>
              </div>

              <div className='p-12'>
                <ResponsiveTable
                  columns={this.getDGFinalTableColumnsData()}
                  data={this.getDGFinalTableColumns()}
                  noDataMessage={`Ainda não existe nenhum lote selecionado para mescla`}
                  skipPagination
                />
              </div>

              <div className='grid-item p-12 p-display-flex p-justify-space-between'>
                <Button
                  color={COLORS.GREY}
                  label={'Cancelar'}
                  onClick={this.onCancel}
                />
              </div>
            </div>
          ) : (
            <div className='grid grid-merge'>
              <div className='grid-item p-12 t-3'>
                <Autocomplete
                  id='protocol'
                  options={this.state.protocols}
                  value={this.state.protocol || ''}
                  onChange={(e, value) => {
                    this.autocompleteDefaultOnChangeSavingID('protocol')(
                      e,
                      value
                    )
                    this.setState({
                      day: null,
                      days: _.isEmpty(value)
                        ? []
                        : _.sortBy(_.keys(this.props.data[value.id].days)),
                      batch: null,
                      batches: [],
                      selectedBatches: [],
                    })
                  }}
                  onKeyDown={this.state.protocol && this.onEnterPress}
                  getOptionLabel={this.autocompleteOptionLabelUsingCustomFieldSavingID(
                    this.state.protocols,
                    'name'
                  )}
                  useDefaultOptionSelected
                  label='Protocolo'
                  error={!_.isEmpty(this.state.protocol_error)}
                  helperText={this.state.protocol_error}
                />
              </div>

              <div className='grid-item p-12 t-3'>
                <Autocomplete
                  id='day'
                  options={this.state.days.filter((day) => day !== 'D0')}
                  value={this.state.day || ''}
                  onChange={(e, value) => {
                    this.autocompleteDefaultOnChange('day')(e, value)
                    this.setState({
                      batch: null,
                      batches: _.isEmpty(value)
                        ? []
                        : _.sortBy(
                          this.props.data[this.state.protocol].days[value],
                          [(batch) => batch.name.toLowerCase()]
                        ),
                      selectedBatches: [],
                    })
                  }}
                  onKeyDown={this.state.day && this.onEnterPress}
                  useDefaultOptionSelected
                  label='Manejo'
                  disabled={_.isEmpty(this.state.protocol)}
                  error={!_.isEmpty(this.state.management_error)}
                  helperText={this.state.management_error}
                />
              </div>

              <div className='grid-item p-10 t-5'>
                <Autocomplete
                  id='batch'
                  options={_.filter(
                    this.state.batches,
                    (batch) =>
                      !_.find(this.state.selectedBatches, { id: batch.id })
                  )}
                  value={this.state.batch || ''}
                  onChange={this.autocompleteDefaultOnChange('batch')}
                  onKeyDown={this.state.batch && this.onEnterPress}
                  getOptionLabel={this.autocompleteOptionLabelUsingCustomField(
                    'name'
                  )}
                  useDefaultOptionSelected
                  label='Lotes Para Mescla'
                  disabled={
                    _.isEmpty(this.state.protocol) || _.isEmpty(this.state.day)
                  }
                  error={!_.isEmpty(this.state.batch_error)}
                  helperText={this.state.batch_error}
                />
              </div>

              <div className='grid-item p-2 t-1 p-display-flex p-align-items-center'>
                <IconButton
                  onClick={this.addBatch}
                  disabled={
                    !this.state.protocol || !this.state.day || !this.state.batch
                  }
                  className='plus-icon'
                >
                  <AddIcon />
                </IconButton>
              </div>

              <div className='p-12'>
                <ResponsiveTable
                  columns={this.getTableColumnsData()}
                  data={this.getTableColumns()}
                  noDataMessage={`Ainda não existe nenhum lote selecionado para mescla`}
                  skipPagination
                />
              </div>

              <div className='grid-item p-12 p-display-flex p-justify-space-between'>
                <Button
                  color={COLORS.GREY}
                  label={'Cancelar'}
                  onClick={this.onCancel}
                />
              </div>
            </div>
          )}

          <Prompt
            visible={verify()}
            title='Página duplicada!'
            message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
            Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
            buttons={[
              {
                autoFocus: false,
              },
            ]}
          />

          <Prompt
            visible={this.state.showingPrompt}
            title={this.handleMergeCorrals(this.state.selectedBatches).title}
            message={this.handleMergeCorrals(this.state.selectedBatches).message}
            buttons={this.handleMergeCorrals(this.state.selectedBatches).buttons}
          />

          <Prompt
            visible={this.state.showingDGFinalPrompt}
            title={this.handleMergeCorrals(this.state.selectedDGFinalBatches).title}
            message={this.handleMergeCorrals(this.state.selectedDGFinalBatches, true).message}
            buttons={this.handleMergeCorrals(this.state.selectedDGFinalBatches, true).buttons}
          />
        </MainContainer>
      </Container>
    )
  }
}

const propsFromDatabase = async (props) => {
  const farm = await utils.getElement(
    AVAILABLE_ENTITIES.FARMS,
    props.match.params.farmId
  )

  const corral = await utils.getElement(
    AVAILABLE_ENTITIES.CORRALS,
    props.match.params.corralId
  )

  const infoBatches = await getBatchesByFarm(farm, true)

  const corrals = await utils.getWithParam(
    AVAILABLE_ENTITIES.CORRALS,
    'farm_id',
    farm.id
  )

  const batches = await utils.getWithParam(
    AVAILABLE_ENTITIES.BATCHES,
    'corral_id',
    props.match.params.corralId
  )

  let data = {}

  let filteredBatches = _.filter(batches, (batch) =>
    _.isEmpty(batch.originalBatchId)
  )

  //Construct hierarchy by protocol -> management -> batches
  if (batches.length > 0) {
    const days = await utils.getDomainValuesBy('Dias')
    const tipoResync = await utils.getDomainValuesBy('Tipo de Ressincronização')
    const manejos = await utils.getDomainValuesBy('Tipo de Manejo')
    const iatf = _.find(manejos, { valor: 'IATF' })

    const superPrecoce = _.find(tipoResync, { valor: 'Super Precoce' }).id
    const precoce = _.find(tipoResync, { valor: 'Precoce' }).id

    filteredBatches = await Promise.all(
      filteredBatches.map(async (batch) => {
        const relatedBatches = _.filter(
          batches,
          (candidate) => candidate.originalBatchId === batch.id
        )

        let batchList = [batch]
        let next = _.find(relatedBatches, {
          parentBatchId: _.last(batchList).id,
        })
        while (next) {
          batchList.push(next)
          next = _.find(relatedBatches, { parentBatchId: next.id })
        }

        let currentBatch = _.last(batchList)

        const isPrecoce =
          currentBatch.tipoResync === superPrecoce ||
          currentBatch.tipoResync === precoce

        const protocol = await utils.getElement(
          AVAILABLE_ENTITIES.PROTOCOLS,
          currentBatch.protocoloId
        )
        if (!protocol) {
          return batch
        }
        const protocolDays = protocol
          ? _.sortBy(
            _.uniq(
              protocol.managementProtocols.map((managementProtocol) =>
                _.find(days, { id: managementProtocol.day })
              )
            ),
            [
              (dia) =>
                dia.valor.startsWith('D')
                  ? parseInt(dia.valor.substring(1))
                  : 100,
            ]
          )
          : []

        const currentDay =
          protocolDays[_.findIndex(protocolDays, { id: currentBatch.dias }) + 1]
        const isLastDay =
          currentDay && currentDay.id === _.last(protocolDays).id
        const hasIatf =
          currentDay &&
          _.find(
            _.filter(protocol.managementProtocols, { day: currentDay.id }),
            {
              managementId: iatf.id,
            }
          )
        const vacas = await utils.getWithParam(
          AVAILABLE_ENTITIES.D0S,
          'batch_id',
          currentBatch.id
        )
        let vacasDG = _.filter(
          await utils.getWithParam(
            AVAILABLE_ENTITIES.D0S,
            'batch_id',
            batch.id
          ),
          (vaca) => !vaca.omit_from_dg_final
        )

        let currentIatf = -1
        let pastDG = false
        let pastDGFinal = false
        let countCowsWithDG = 0
        let countCowsWithDGFinal = 0

        _.forEach(vacas, (vaca) => {
          if (vaca.iatf > currentIatf) {
            currentIatf = vaca.iatf
          }

          if (
            ((vaca.condicaoDG !== null && vaca.condicaoDG.length > 0) ||
              (vaca.observacaoDG !== null &&
                (vaca.observacaoDG.length > 0 ||
                  vaca.observacaoDG === '[N/A]'))) &&
            batch.isFinalize
          ) {
            countCowsWithDG++
          }
          if (
            (vaca.condicaoDGFinal !== null &&
              vaca.condicaoDGFinal.length > 0) ||
            (vaca.observacao_DG_Final !== null &&
              (vaca.observacao_DG_Final.length > 0 ||
                vaca.observacao_DG_Final === '[N/A]'))
          ) {
            countCowsWithDGFinal++
          }
        })

        if (
          countCowsWithDG === vacasDG.length &&
          vacasDG.length !== 0 &&
          batch.isFinalize
        ) {
          pastDG = true
        }
        if (countCowsWithDGFinal === vacasDG.length && vacasDG.length !== 0) {
          pastDGFinal = true
        }

        let parentPastDG = false
        let countCowsWithParentDG = 0

        if (currentBatch.parentBatchId !== null) {
          const previousCows = await utils.getWithParam(
            AVAILABLE_ENTITIES.D0S,
            'batch_id',
            currentBatch.parentBatchId
          )
          _.forEach(previousCows, (vaca) => {
            if (
              (vaca.condicaoDG !== null && vaca.condicaoDG.length > 0) ||
              (vaca.observacaoDG !== null &&
                (vaca.observacaoDG.length > 0 || vaca.observacaoDG === '[N/A]'))
            ) {
              countCowsWithParentDG++
            }
          })

          if (
            countCowsWithParentDG === previousCows.length &&
            previousCows.length !== 0 &&
            batch.isFinalize
          ) {
            parentPastDG = true
          }
        }

        const nextDay = currentDay && currentDay.valor

        const dayLabel =
          isLastDay || hasIatf
            ? 'IATF'
            : !currentBatch.dias || currentBatch.dias === ''
              ? currentBatch.isResync && !isPrecoce
                ? 'DG/D0'
                : 'D0'
              : nextDay
                ? currentBatch.isResync &&
                  isPrecoce &&
                  !parentPastDG &&
                  !currentBatch.isFinalize
                  ? `DG/${nextDay}`
                  : nextDay
                : pastDG
                  ? pastDGFinal
                    ? null
                    : 'DG Final'
                  : 'DG'

        const target =
          isLastDay || hasIatf
            ? 'IATF'
            : !currentBatch.dias || currentBatch.dias === ''
              ? currentBatch.isResync
                ? isPrecoce
                  ? 'D0Resync'
                  : 'DGRessinc'
                : 'D0'
              : nextDay
                ? currentBatch.isResync && isPrecoce && !parentPastDG
                  ? 'DGRessinc'
                  : 'DN'
                : pastDG
                  ? pastDGFinal
                    ? null
                    : 'DGFinal'
                  : 'DG'

        return {
          originalBatch: batch,
          currentBatch,
          protocol,
          name: batch.nomeLote,
          retiroId: batch.retiroId,
          iatf: currentIatf + 1,
          currentDay: dayLabel,
          target,
          id: currentBatch.id,
        }
      })
    )
  }

  filteredBatches = filteredBatches.filter(
    (batch) => batch.iatf > 0 && batch.currentDay !== null
  )

  for (const batch of filteredBatches) {
    if (!data[batch.protocol.id]) {
      data[batch.protocol.id] = {
        name: batch.protocol.name,
        days: {
          [batch.currentDay]: [batch],
        },
      }
    } else {
      if (!data[batch.protocol.id].days[batch.currentDay]) {
        data[batch.protocol.id].days[batch.currentDay] = [batch]
      } else {
        data[batch.protocol.id].days[batch.currentDay].push(batch)
      }
    }
  }

  const user = await getLoggedUser()

  return {
    infoBatches: infoBatches.filter((batch) => batch.retiroId === corral.id),
    farm,
    corral,
    corrals,
    batches,
    data,
    user
  }
}

export default utils.withPropsFromDatabase(
  propsFromDatabase,
  withRouter(BatchMerge)
)
